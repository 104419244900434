import { InputField } from '../../form/InputField';
import React from 'react';
import { ChangeHandler } from '../../form/events';
import { FaTrash } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { RoomType } from '../../main/types';

type RoomTypeRowProps = {
  roomType: RoomType;
  disabled: boolean;
  index: number;
  onChangeRoomType: ChangeHandler<HTMLInputElement>;
  onChangeRoomPrice: ChangeHandler<HTMLInputElement>;
  onChangeRoomBeds: ChangeHandler<HTMLInputElement>;
  onChangeRoomCount: ChangeHandler<HTMLInputElement>;
  onChangeRoomDescription: ChangeHandler<HTMLInputElement>;
  onDeleteRoomType: () => void;
};
const RoomTypeRow: React.FunctionComponent<RoomTypeRowProps> = ({
  roomType: r,
  disabled,
  index,
  onChangeRoomBeds,
  onChangeRoomCount,
  onChangeRoomDescription,
  onChangeRoomPrice,
  onChangeRoomType,
  onDeleteRoomType,
}: RoomTypeRowProps) => (
  <div
    className={`roomtype row ${index % 2 === 0 ? 'odd' : 'even'}`}
    key={index}
  >
    <div className="col-md-1 roomtype-index align-content-center">
      <div className={'pt-2'}>{index + 1}</div>
    </div>
    <div className="col-md-10">
      <div className="row">
        <InputField
          className="col-md-6 mb-3 roomtype-type"
          disabled={disabled}
          label="Type:"
          placeholder="Type"
          value={r.type}
          onChange={onChangeRoomType}
        />
        <InputField
          className="col-md-6 mb-3 roomtype-desc"
          disabled={disabled}
          label="Desc.:"
          placeholder="Description"
          value={r.description}
          onChange={onChangeRoomDescription}
        />
      </div>
      <div className="row">
        <InputField
          className="col-md-4 mb-3 roomtype-price"
          disabled={disabled}
          label="Price/night:"
          placeholder="Price per night"
          value={r.pricePerNight}
          onChange={onChangeRoomPrice}
        />
        <InputField
          className="col-md-4 mb-3 roomtype-beds"
          disabled={disabled}
          label="Beds:"
          placeholder="Beds"
          value={'' + r.beds}
          onChange={onChangeRoomBeds}
        />
        <InputField
          className="col-md-4 mb-3 roomtype-count"
          disabled={disabled}
          label="Count:"
          placeholder="Count"
          value={'' + r.count}
          onChange={onChangeRoomCount}
        />
      </div>
    </div>
    <div className="col-md-1 roomtype-index align-content-center">
      <div className={'align-middle'}>
        <Button
          className="p-0 pt-1"
          disabled={disabled}
          variant="none"
          title={`Delete ${r.type}`}
          onClick={onDeleteRoomType}
        >
          <FaTrash />
        </Button>
      </div>
    </div>
  </div>
);

export default RoomTypeRow;
