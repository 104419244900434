import { InputField } from '../../form/InputField';
import React from 'react';
import { ChangeHandler } from '../../form/events';
import { FaTrash } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { FlatFee } from '../../main/types';

type FlatFeeRowProps = {
  flatFee: FlatFee;
  disabled: boolean;
  index: number;
  onChangeFlatFee: ChangeHandler<HTMLInputElement>;
  onChangeFlatFeePrice: ChangeHandler<HTMLInputElement>;
  onChangeFlatFeeDescription: ChangeHandler<HTMLInputElement>;
  onChangeFlatFeeSponsoring: ChangeHandler<HTMLInputElement>;
  onDeleteFlatFee: () => void;
};
const FlatFeeRow: React.FunctionComponent<FlatFeeRowProps> = ({
  flatFee: f,
  disabled,
  index,
  onChangeFlatFeeDescription,
  onChangeFlatFeePrice,
  onChangeFlatFeeSponsoring,
  onChangeFlatFee,
  onDeleteFlatFee,
}: FlatFeeRowProps) => (
  <div
    className={`flatfee row ${index % 2 === 0 ? 'odd' : 'even'}`}
    key={index}
  >
    <div className="col-md-1 flatfee-index align-content-center">
      <div className="pt-2">{index + 1}</div>
    </div>
    <div className="col-md-10">
      <div className="row">
        <InputField
          className="col-md-3 mb-3 flatfee-type"
          disabled={disabled}
          label="Type:"
          placeholder="Type"
          value={f.type}
          onChange={onChangeFlatFee}
        />
        <InputField
          className="col-md-9 mb-3 flatfee-desc"
          disabled={disabled}
          label="Desc.:"
          placeholder="Description"
          value={f.description}
          onChange={onChangeFlatFeeDescription}
        />
      </div>
      <div className={'row'}>
        <InputField
          className="col-md-3 mb-3 flatfee-price"
          disabled={disabled}
          label="Price"
          placeholder="Price"
          value={f.price}
          onChange={onChangeFlatFeePrice}
        />
        <div className="input-group col-md-4 mb-3 flatfee-sponsoring">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <input
                id={`flatfee-sponsoring-${index}`}
                type="checkbox"
                aria-labelledby={`flatfee-sponsoring-${index}-label`}
                value="excludeFromSponsoring"
                checked={f.excludeFromSponsoring ?? false}
                onChange={onChangeFlatFeeSponsoring}
              />
            </div>
          </div>
          <label
            id={`flatfee-sponsoring-${index}-label`}
            className={'form-control input-group-text'}
          >
            <small>Exclude from sponsoring</small>
          </label>
        </div>
      </div>
    </div>
    <div className="col-md-1 flatfee-index align-content-center align-middle">
      <div className={'align-middle'}>
        <Button
          className="pt-1 p-0"
          disabled={disabled}
          variant="none"
          title={`Delete ${f.type}`}
          onClick={onDeleteFlatFee}
        >
          <FaTrash />
        </Button>
      </div>
    </div>
  </div>
);

export default FlatFeeRow;
