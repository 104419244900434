import { config } from '../../config';
import { UUID } from '../types';
import axios from 'axios';

const invoicesApi: string = config.apiEndpoints.invoices;
const invoicesEndpoint: string = `${invoicesApi}/invoices`;

export interface InvoicesApi {
  createAttendeeInvoice: (
    attendeeId: UUID,
    conferenceId: UUID,
  ) => Promise<string>;
}

const createInvoiceByAttendeeId = async (
  attendeeId: UUID,
  conferenceId: UUID,
): Promise<string> => {
  return await axios.post(`${invoicesEndpoint}/`, {
    attendeeId: attendeeId,
    conferenceId: conferenceId,
  });
};

export class Invoices implements InvoicesApi {
  public async createAttendeeInvoice(
    attendeeId: UUID,
    conferenceId: UUID,
  ): Promise<string> {
    console.log(
      `create invoice for attendee ${attendeeId} and conference ${conferenceId}`,
    );
    return await createInvoiceByAttendeeId(attendeeId, conferenceId);
  }
}
