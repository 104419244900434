import React, { ChangeEvent, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LoginCard } from './LoginCard';
import { useAuth } from '../main/AuthProvider';

export const LoginScreen = (): React.ReactElement => {
  const history = useHistory();
  const location = useLocation<any>();
  const auth = useAuth();

  let { from } = location.state || { from: { pathname: '/' } };

  const login = async (username: string, password: string): Promise<void> =>
    await auth.signIn(username, password).then(() => {
      history.replace(from);
    });

  const [error, setError] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleFailure = (e: Error): void => {
    setError(true);
    setTimeout(() => setError(false), 500);
    console.log('Error while logging in:', e);
  };
  const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>): void =>
    setUsername(event?.target?.value || '');
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>): void =>
    setPassword(event?.target?.value || '');
  const handleClear = (): void => {
    setUsername('');
    setPassword('');
    setError(false);
  };
  const handleLogin = async (): Promise<void> => {
    await login(username, password).catch((e) => {
      handleFailure(e);
    });
  };
  return (
    <div>
      <div className="row">&nbsp;</div>
      <div className="row">
        <div className="col-lg-2 col-md-2">&nbsp;</div>
        <div className="col-lg-8 col-md-8 center">
          <LoginCard
            error={error}
            username={username}
            password={password}
            onUsernameChange={handleUsernameChange}
            onPasswordChange={handlePasswordChange}
            onClear={handleClear}
            onSubmit={handleLogin}
          />
        </div>
        <div className="col-lg-2 col-md-2">&nbsp;</div>
      </div>
    </div>
  );
};
