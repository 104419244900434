import { Button, Nav, NavDropdown } from 'react-bootstrap';
import React, { useCallback, useState } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import './EventsSubnav.scss';
import { useConference } from '../main/ConferenceProvider';
import moment from 'moment';
import { Conference, ConferenceState } from '../main/types';

const EMPTY_CONF: Conference = {
  id: '',
  title: 'New Conference',
  location: 'Virtual',
  state: ConferenceState.PREPARATION,
  year: moment().year(),
};

type EventsSubnavProps = {
  onCreateEvent?: (conf: Conference) => void;
};
const eventTitle = (conf: Conference | undefined): string => {
  return conf ? `${conf.title} ${conf.year}` : 'Not available';
};

export const EventsSubnav = ({
  onCreateEvent,
}: EventsSubnavProps): React.ReactElement => {
  const location = useLocation<any>();
  const { url } = useRouteMatch();
  const conferenceContext = useConference();
  const getChoices = useCallback(async () => {
    return await conferenceContext.choices();
  }, [conferenceContext]);

  const [conference, setConference] = useState<Conference | undefined>(
    conferenceContext.current,
  );

  const [conferences, setConferences] = useState<Conference[]>([]);
  if (conferences.length === 0) {
    getChoices().then((c) => {
      setConferences(c);
      setConference(conferenceContext.current ?? c[0]);
    });
  }

  const onSelect = (result: string | null): void => {
    conferenceContext.select(result).then(() => {
      const next = conferences.find((c) => eventTitle(c) === result);
      setConference(next);
    });
  };

  return (
    <Nav
      activeKey={location.pathname}
      className="row subnav shadow-sm justify-content-end p-1 pr-4"
    >
      <Nav.Item>
        <Nav.Link
          eventKey="/events/dashboard"
          as={Link}
          to={`${url}/dashboard`}
          className="text-info"
        >
          Dashboard
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          eventKey="/events/applicants"
          as={Link}
          to={`${url}/applicants`}
          className="text-info"
        >
          Applicants
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          eventKey="/events/lottery"
          as={Link}
          to={`${url}/lottery`}
          className="text-info"
        >
          Lottery
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          eventKey="/events/attendees"
          as={Link}
          to={`${url}/attendees`}
          className="text-info"
        >
          Attendees
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          eventKey="/events/details"
          as={Link}
          to={`${url}/details`}
          className="text-info"
        >
          Conference
        </Nav.Link>
      </Nav.Item>

      <NavDropdown
        id="nav-dropdown"
        title={eventTitle(conference)}
        className="text-info bg-black"
        onSelect={onSelect}
      >
        {conferences.map((c) => (
          <NavDropdown.Item
            key={eventTitle(c)}
            active={eventTitle(c) === eventTitle(conference)}
            className="text-info bg-black"
            eventKey={eventTitle(c)}
          >
            {eventTitle(c)}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
      <Nav.Item>
        <Button
          className="mt-1 p-0 pl-2 pr-2"
          variant="info"
          onClick={() => onCreateEvent && onCreateEvent(EMPTY_CONF)}
        >
          +
        </Button>
      </Nav.Item>
    </Nav>
  );
};
