import React from 'react';

export type UUID = string;
export type Identifier = string;

export type Iterable = { [key: string]: any };

export type TableRow = { index?: number };

export type Entity = {
  id: UUID;
};

export type RoomTypeName =
  | (Identifier & 'new')
  | 'single'
  | 'double-shared'
  | 'junior-double'
  | 'junior-double-shared';

export type RoomType = Iterable & {
  type: RoomTypeName;
  description: string;
  pricePerNight: string;
  beds: number;
  count: number;
};
export type FlatFee = Iterable & {
  type: Identifier;
  description: string;
  price: string;
  excludeFromSponsoring?: boolean;
};

export enum ConferenceState {
  PREPARATION = 'PREPARATION',
  REGISTRATION = 'REGISTRATION',
  IN_PROGRESS = 'IN_PROGRESS',
  CLEANUP = 'CLEANUP',
  CONCLUDED = 'CONCLUDED',
}

export type Conference = Iterable &
  TableRow &
  Entity & {
    byline?: string;
    diversityRatio?: number;
    endDate?: string;
    flatFees?: FlatFee[];
    location: string;
    maxSeatsPerSponsor?: number;
    roomTypes?: RoomType[];
    startDate?: string;
    state?: ConferenceState;
    title: string;
    year: number;
  };
export type Template = TableRow & {
  title: string;
  subject: string;
  htmlText?: string;
  text: string;
};

export type HistoryEntry = TableRow & {
  date: string;
  time: string;
  template: string;
  username?: string;
  email?: string;
};

export type Subscriber = TableRow &
  Entity & {
    email: string;
    name: string;
  };

export type Applicant = TableRow &
  Entity &
  Iterable & {
    conferenceId: UUID;
    email: string;
    name: string;
    roomTypeSelected: string[];
    roommate?: string;
    diversitySelected: string;
    requestedFinancialAid: boolean;
  };

export enum BillingStatus {
  NOT_INVOICED = 'NOT_INVOICED',
  INVOICED = 'INVOICED',
  PAID = 'PAID',
  OVERDUE = 'OVERDUE',
}

export type AttendeeReason = undefined | 'staff' | 'sponsor' | 'trainer';

export type Address = {
  firstname?: string;
  lastname?: string;
  address1?: string;
  address2?: string;
  postal?: string;
  city?: string;
  country?: string;
};

export type BillingAddress = Address & {
  company?: string;
  vat?: string;
};

export interface AttendeeInvoice {
  invoiceIssued: Date;
  externalId: string;
}

export type Attendee = TableRow &
  Entity &
  Iterable & {
    conferenceId: UUID;
    email: string;
    billingStatus?: BillingStatus;
    personalAddress?: Address;
    billingAddress?: BillingAddress;
    confirmationKey?: string;
    requestedFinancialAid?: boolean;
    financialAidContrbution?: Price;
    nickname: string;
    reason?: AttendeeReason;
    diversitySelected: string;
    booking: Booking;
    attendeeInvoice?: AttendeeInvoice;
  };

export type Sponsor = TableRow &
  Entity & {
    name: string;
    url: string;
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    logo: string;
  };

export type SponsorSlot = TableRow &
  Entity & {
    conference: Identifier;
    logo: string;
    url: string;
    name: string;
    donation: Price;
    numberOfSeats: number;
    paymentReceived: boolean;
    showLogo: boolean;
  };

export type Price = string;

export type Stats = {
  attendeeCount: number;
  attendeesConfirmed: number;
  sponsoringAmount: Price;
  financialAidAmount: Price;
  financialAidRequestersCount: number;
  expensesAmount: Price;
  bookedPackages: Record<Package, number>;
  bookedBeds: Record<string, number>;
  bookedBedsDiversity: Record<string, number>;
};

export type Day = (Identifier & 'Wed') | 'Thu' | 'Fri' | 'Sat' | 'Sun';
export type Package = (Identifier & 'conference') | 'training' | 'workshops';

export type RoomOccupancy = {
  roomType: RoomTypeName;
  email: string;
  roommate?: string;
  diversity: boolean;
  family: boolean;
  daysSelected: Day[];
};

export type Childcare = {
  children: number;
  details: string;
};

export type Booking = TableRow & {
  email: string;
  room: RoomOccupancy;
  packages: Package[];
  swag?: boolean;
  swagSize?: string;
  dietary?: string;
  childcare?: Childcare;
};

export type LotteryInput = {
  conferenceId: UUID;
  bedsToAllocate: Record<string, number>;
  diversityPercentage: number;
};

export declare type SelectCallback = (
  eventKey: string | null,
  e: React.SyntheticEvent<unknown>,
) => void;

export type LoggableEvent = Iterable & {
  type?: string;
  timestamp: number;
  correlationId?: string;
};
export type Process = {
  index?: number;
  timestamp: string;
  correlationId: string;
  command: string;
  lastEvent: string;
};
