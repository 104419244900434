import { useConference } from '../../main/ConferenceProvider';
import { useSponsors } from '../../main/SponsorsProvider';
import { useApplicants } from '../../main/ApplicantsProvider';
import React, { useEffect, useState } from 'react';
import {
  Applicant,
  Attendee,
  Booking,
  Conference,
  Price,
  SponsorSlot,
  Stats,
} from '../../main/types';
import { formatEuro, parsePrice } from '../../common/calculation';
import './EventsDashboard.scss';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import { useAttendees } from '../../main/AttendeesProvider';
import { ChartData } from 'chart.js';
import { useBooking } from '../../main/BookingProvider';
import { ensure } from '../../common/ensure';

const calculatePeopleData = <T extends Attendee | Applicant>(
  people: T[],
  backgroundColor: string[],
): ChartData<'doughnut', number[], string> => {
  const labels = ['Single', 'Single Diversity', 'Shared', 'Shared Diversity'];
  const single: T[] =
    people?.filter(
      (a: T) =>
        (a.roommate
          ? a.roommate
          : a.booking && a.booking.roommate
            ? a.booking.roommate
            : '') === '',
    ) ?? [];
  const shared: T[] =
    people?.filter(
      (a: T) =>
        (a.roommate
          ? a.roommate
          : a.booking && a.booking.roommate
            ? a.booking.roommate
            : '') !== '',
    ) ?? [];
  const data = people
    ? [
        single.length,
        single.filter((a) => a.diversitySelected !== 'no').length,
        shared.length,
        shared.filter((a) => a.diversitySelected !== 'no').length,
      ]
    : [];
  const datasets = [{ data: data, backgroundColor }];
  return { labels: labels, datasets: datasets };
};

const calculateRoomData = (
  stats: Stats | undefined,
  backgroundColor: string[],
): ChartData<'doughnut', number[], string> => {
  const labels = ['Single', 'Single Diversity', 'Shared', 'Shared Diversity'];
  const single = stats
    ? stats.bookedBeds['single'] + stats.bookedBeds['junior-double']
    : 0;
  const singleDiverse = stats
    ? stats.bookedBedsDiversity['single'] +
      stats.bookedBedsDiversity['junior-double']
    : 0;
  const shared = stats
    ? stats.bookedBeds['double-shared'] +
      stats.bookedBeds['junior-double-shared']
    : 0;
  const sharedDiverse = stats
    ? stats.bookedBedsDiversity['double-shared'] +
      stats.bookedBedsDiversity['junior-double-shared']
    : 0;
  const data = stats ? [single, singleDiverse, shared, sharedDiverse] : [];
  const datasets = [{ data: data, backgroundColor }];
  return { labels: labels, datasets: datasets };
};
const calculatePackageData = (
  stats: Stats | undefined,
  backgroundColor: string[],
): ChartData<'doughnut', number[], string> => {
  const labels = ['Conference', 'Training Day', 'Workshop Day'];
  const conference = stats ? stats.bookedPackages['conference'] : 0;
  const training = stats ? stats.bookedPackages['training'] : 0;
  const workshop = stats ? stats.bookedPackages['workshops'] : 0;
  const data = stats ? [conference, training, workshop] : [];
  const datasets = [{ data: data, backgroundColor }];
  return { labels: labels, datasets: datasets };
};

const calculateSponsoringTotal = (slots: SponsorSlot[]): Price =>
  formatEuro(slots.reduce((total, s) => total + parsePrice(s.donation), 0));
const calculateSponsoringUnpaid = (slots: SponsorSlot[]): Price =>
  formatEuro(
    slots.reduce(
      (total, s) => total + (!s.paymentReceived ? parsePrice(s.donation) : 0),
      0,
    ),
  );
const calculateSponsoredSeats = (slots: SponsorSlot[]): number =>
  slots.reduce((total, s) => total + s.numberOfSeats, 0);
const calculateAssignedSponsoredSeats = (atts: Attendee[]): number =>
  atts.filter((a) => a.reason === 'sponsor').length;
const calculateDiversityPeople = <T extends Attendee | Applicant>(
  ppl: T[],
): number => ppl.filter((a) => a.diversitySelected !== 'no').length;
const calculateDiversityBeds = (stats?: Stats): number =>
  stats
    ? Object.values(stats.bookedBedsDiversity).reduce((sum, n) => sum + n, 0)
    : 0;
const calculateAvailableSeats = (conf: Conference): number =>
  conf.roomTypes?.reduce((seats, r) => seats + r.beds * r.count, 0) ?? 0;
const calculateTotalPayable = (
  bookings: Booking[],
  stats?: Stats,
  conference?: Conference,
): number => {
  if (stats && conference !== undefined) {
    const totalFees = Object.entries(stats.bookedPackages).reduce(
      (total, [pkgName, amount]) => {
        const pkg = conference.flatFees?.find((f) => f.type === pkgName);
        const fee: number = pkg
          ? Math.round(amount * parsePrice(pkg.price) * 100)
          : 0;
        return total + fee;
      },
      0,
    );
    const totalRoomCharges = bookings
      .map((b) => {
        const roomType = ensure(conference).roomTypes?.find(
          (r) => r.type === b.room.roomType,
        );
        return Math.round(
          parsePrice(roomType?.pricePerNight ?? '0€') *
            b.room.daysSelected.length *
            100,
        );
      })
      .reduce((total, indiv) => total + indiv, 0);
    return (totalFees + totalRoomCharges) / 100;
  } else return 0;
};
const calculateAppliedSponsoring = (stats?: Stats): number => {
  return (
    parsePrice(stats?.sponsoringAmount ?? '0€') -
    parsePrice(stats?.expensesAmount ?? '0€')
  );
};

export function EventsDashboard(): React.ReactElement {
  const conference = useConference();
  const sponsors = useSponsors();
  const applicants = useApplicants();
  const attendees = useAttendees();
  const bookingContext = useBooking();

  const [conf, setConf] = useState<Conference | undefined>();
  const [apps, setApps] = useState<Applicant[]>([]);
  const [atts, setAtts] = useState<Attendee[]>([]);
  const [books, setBooks] = useState<Booking[]>([]);
  const [slots, setSlots] = useState<SponsorSlot[]>([]);
  const [stats, setStats] = useState<Stats>();

  useEffect(() => {
    let isSubscribed = true;
    if (conf === undefined) {
      if (conference.current) setConf(conference.current);
      else {
        conference.choices().then((c) => isSubscribed && setConf(c[0]));
      }
    } else {
      applicants
        .getApplicants(conf?.id ?? '')
        .then((a) => isSubscribed && setApps(a));
      attendees.getAttendees(conf?.id ?? '').then((a) => {
        if (isSubscribed) {
          setAtts(a);
          setBooks(a.map((it) => it.booking));
        }
      });
      sponsors
        .getSponsorSlots(conf?.id ?? '')
        .then((s) => isSubscribed && setSlots(s));
      bookingContext
        .getStats(conf?.id ?? '')
        .then((s) => isSubscribed && setStats(s));
    }
    return () => {
      isSubscribed = false;
    };
  }, [conference, bookingContext, conf, applicants, attendees, sponsors]);

  const labels =
    conf?.roomTypes &&
    ['Occupied'].concat(conf?.roomTypes?.map((r) => r.description));
  const roomsData =
    conf?.roomTypes &&
    [atts?.length ?? 0].concat(
      conf?.roomTypes?.map(
        (r) => r.beds * r.count - (stats?.bookedBeds[r.type] ?? 0),
      ),
    );
  const roomsBackgroundColor = [
    '#000',
    '#009932',
    '#ff7f20',
    '#008b8b',
    '#639',
  ];
  const roomsDatasets = [
    { data: roomsData, backgroundColor: roomsBackgroundColor },
  ];
  const peopleBackgroundColor = [...roomsBackgroundColor].slice(
    1,
    roomsBackgroundColor.length,
  );
  const applicantsChartData = calculatePeopleData(apps, peopleBackgroundColor);
  const attendeeChartData = calculateRoomData(stats, peopleBackgroundColor);
  const packagesChartData = calculatePackageData(stats, peopleBackgroundColor);
  const payment = calculateTotalPayable(books, stats, conf);
  const appliedSponsoring: number = calculateAppliedSponsoring(stats);
  const uniqueChildcareRequests = books
    .filter((b) => b.childcare)
    .reduce(
      (unique, b) =>
        unique.some((b1) => b1.room.roommate === b.room.email)
          ? unique
          : unique.concat([b]),
      [] as Booking[],
    );
  const totalChildcareChildren = uniqueChildcareRequests.reduce(
    (total, r) => total + r.childcare!.children,
    0,
  );
  return (
    <div className={`container dashboard ${conf ? '' : 'd-none'}`}>
      <div className={'row'}>
        <div className={'col-lg-12'}>
          <div className={'card mt-4'}>
            <div className={'card-header'}>
              {conf?.title + ' ' + conf?.year}
            </div>
          </div>
        </div>
      </div>
      <div className={'row'}>
        <div className={'col-lg-4 col-md-6 col-sm-12'}>
          <div className={'card mt-4'}>
            <div className={'card-header'}>Available Rooms</div>
            <div className={'card-body p-4 text-center'}>
              <Doughnut data={{ labels, datasets: roomsDatasets }} />
              <h2>
                {(conf ? calculateAvailableSeats(conf) : 0) -
                  atts.length +
                  ' / ' +
                  (conf ? calculateAvailableSeats(conf) : 0)}
              </h2>
            </div>
            <div className={'card-footer'}></div>
          </div>
        </div>
        <div className={'col-lg-4 col-md-6 col-sm-12 mb-4'}>
          <div className={'card mt-4'}>
            <div className={'card-header'}>Applicants / Diversity</div>
            <div className={'card-body p-4 text-center'}>
              <Doughnut data={applicantsChartData} />
              <h2>{apps.length + ' / ' + calculateDiversityPeople(apps)}</h2>
            </div>
            <div className={'card-footer'}></div>
          </div>
        </div>
        <div className={'col-lg-4 col-md-6 col-sm-12 mb-4'}>
          <div className={'card mt-4'}>
            <div className={'card-header'}>
              Seats / Div. seats / Actual Div.
            </div>
            <div className={'card-body p-4 text-center'}>
              <Doughnut data={attendeeChartData} />
              <h2>
                {atts.length +
                  ' / ' +
                  calculateDiversityBeds(stats) +
                  ' / ' +
                  calculateDiversityPeople(atts)}
              </h2>
            </div>
            <div className={'card-footer'}></div>
          </div>
        </div>
      </div>
      <div className={'row'}>
        <div className={'col-lg-4 col-md-6 col-sm-12'}>
          <div className={'card mt-4'}>
            <div className={'card-header'}># of Sponsors</div>
            <div className={'card-body p-4 text-center'}>
              <h2>{slots.length}</h2>
            </div>
            <div className={'card-footer'}></div>
          </div>
        </div>
        <div className={'col-lg-4 col-md-6 col-sm-12'}>
          <div className={'card mt-4'}>
            <div className={'card-header'}>Sponsoring Total / Unpaid</div>
            <div className={'card-body p-4 text-center'}>
              <h2>
                {calculateSponsoringTotal(slots)} /{' '}
                {calculateSponsoringUnpaid(slots)}
              </h2>
            </div>
            <div className={'card-footer'}></div>
          </div>
        </div>
        <div className={'col-lg-4 col-md-6 col-sm-12'}>
          <div className={'card mt-4'}>
            <div className={'card-header'}>Sponsored Seats / Assigned</div>
            <div className={'card-body p-4 text-center'}>
              <h2>
                {calculateSponsoredSeats(slots) +
                  ' / ' +
                  calculateAssignedSponsoredSeats(atts)}
              </h2>
            </div>
            <div className={'card-footer'}></div>
          </div>
        </div>
      </div>
      <div className={'row'}>
        <div className={'col-lg-4 col-md-6 col-sm-12'}>
          <div className={'card mt-4'}>
            <div className={'card-header'}>Attendees confirmed</div>
            <div className={'card-body p-4 text-center'}>
              <h2>
                {stats?.attendeesConfirmed}/{stats?.attendeeCount}
              </h2>
            </div>
            <div className={'card-footer'}></div>
          </div>
        </div>
        <div className={'col-lg-4 col-md-6 col-sm-12'}>
          <div className={'card mt-4'}>
            <div className={'card-header'}>Packages booked</div>
            <div className={'card-body p-4 text-center'}>
              <Doughnut data={packagesChartData} />
            </div>
            <div className={'card-footer'}></div>
          </div>
        </div>
        <div className={'col-lg-4 col-md-6 col-sm-12'}>
          <div className={'card mt-4'}>
            <div className={'card-header'}>Total payable</div>
            <div className={'card-body p-4 text-center'}>
              {formatEuro(payment)}
              <br />
              (- {formatEuro(appliedSponsoring)})<br />
              <strong>{formatEuro(payment - appliedSponsoring)}</strong>
            </div>
            <div className={'card-footer'}></div>
          </div>
        </div>
      </div>
      <div className={'row'}>
        <div className={'col-lg-4 col-md-6 col-sm-12'}>
          <div className={'card mt-4'}>
            <div className={'card-header'}>Childcare Requests / # Children</div>
            <div className={'card-body p-4 text-center'}>
              {uniqueChildcareRequests.length} / {totalChildcareChildren}
            </div>
            <div className={'card-footer'}></div>
          </div>
        </div>
        <div className={'col-lg-4 col-md-6 col-sm-12'}>
          <div className={'card mt-4'}>
            <div className={'card-header'}>
              Financial Aid (Total / Requesters = Share)
            </div>
            <div className={'card-body p-4 text-center'}>
              {stats?.financialAidAmount ?? '0,00€'}/{' '}
              {stats?.financialAidRequestersCount ?? 0} req. ={' '}
              {formatEuro(
                parsePrice(stats?.financialAidAmount ?? '0') > 0
                  ? parsePrice(stats?.financialAidAmount ?? '0') /
                      (stats?.financialAidRequestersCount ?? 1)
                  : 0,
              )}
            </div>
            <div className={'card-footer'}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
