import { Auth } from 'aws-amplify';
import axios from 'axios';

const handleSuccess = (info: any): void => {
  axios.defaults.headers.common['Authorization'] =
    info.signInUserSession.idToken.jwtToken;
  console.log('Successfully logged in:', info);
};

export const signIn = async (
  username: string,
  password: string,
): Promise<any> => {
  let info = await Auth.signIn(username, password);
  handleSuccess(info);
  return info;
};

export const signOut = (): Promise<void> => {
  return Auth.signOut();
};
