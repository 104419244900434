import React, { useEffect } from 'react';
import { PagingTable, RowMapper } from '../table/PagingTable';
import { LoggableEvent, Process, UUID } from '../main/types';
import { useEvents } from '../main/EventsProvider';
import './OperationsProcesses.scss';
import { useHistory } from 'react-router-dom';
import { eventsToProcesses } from './operations-aggregation';

const ProcessesTableHeader = (): React.ReactElement => (
  <tr>
    <th>#</th>
    <th>Timestamp</th>
    <th>Entry Command</th>
    <th>Latest Event</th>
    <th>Correlation ID</th>
  </tr>
);

const ProcessesHeader = (): React.ReactElement => (
  <div className="mb-0">
    <h5>Process overview (last 7 days)</h5>
  </div>
);

export const OperationsProcesses = (): React.ReactElement => {
  const eventContext = useEvents();
  const history = useHistory();

  const [events, setEvents] = React.useState<LoggableEvent[]>([]);
  const [processes, setProcesses] = React.useState<Process[]>([]);

  useEffect(() => {
    if (events.length === 0) {
      eventContext
        .getEvents()
        .then((result) => {
          setEvents(result);
        })
        .catch((err) => console.error(err));
    } else {
      setProcesses(eventsToProcesses(events));
    }
  }, [eventContext, events]);

  const onRowClick = (correlationId: UUID) => {
    history.push(`/operations/timeline/${correlationId}`);
  };

  const visible: string = events !== undefined ? '' : 'd-none';

  const processesMapper: RowMapper = (row, index) => {
    row.index = index! + 1;
    return (
      <ProcessesListRow
        key={index!}
        process={row as Process}
        onRowClick={onRowClick}
      />
    );
  };

  return (
    <>
      <PagingTable
        rows={processes}
        className={'processes ' + visible}
        listHeader={<ProcessesHeader />}
        tableHeader={<ProcessesTableHeader />}
        emptyRow={<EmptyProcessesListRow />}
        rowMapper={processesMapper}
      />
    </>
  );
};

type ProcessesListRowProps = {
  process: Process;
  onRowClick: (correlationId: UUID) => void;
  key: number;
};

const highlightColor = (event: string) => {
  return event
    ? event.includes('ErrorEvent')
      ? ' text-danger'
      : event.includes('Failed')
        ? ' text-warning'
        : 'text-info'
    : '';
};

export const ProcessesListRow = ({
  process,
  onRowClick,
}: ProcessesListRowProps): React.ReactElement => (
  <tr onClick={() => onRowClick(process.correlationId!)}>
    <td className="text-center">{process.index!}</td>
    <td className="text-center">{process.timestamp}</td>
    <td className="text-center">{process.command ?? 'n/a'}</td>
    <td className={`text-center ${highlightColor(process.lastEvent)}`}>
      {process.lastEvent ?? 'n/a'}
    </td>
    <td className="text-center">{process.correlationId}</td>
  </tr>
);

export const EmptyProcessesListRow = (): React.ReactElement => (
  <tr>
    <td className="text-center" colSpan={5}>
      There are currently no active processes.
    </td>
  </tr>
);
