import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  FormControl,
  InputGroup,
  Modal,
} from 'react-bootstrap';
import { InputField } from '../../form/InputField';
import './SponsorAssignModal.scss';
import { SelectCallback, Sponsor, SponsorSlot } from '../../main/types';

type Props = {
  assign: SponsorSlot;
  sponsors: Sponsor[];
  onChangeSponsor: (sponsor: Sponsor) => void;
  onChangeDonation: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeNumberOfSeats: (numberOfSeats: number) => void;
  onChangePaymentReceived: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeShowLogoReceived: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  onKeyPress?: () => void;
  onClose: () => void;
  onSave: () => Promise<void>;
};
export const SponsorAssignModal = (props: Props): React.ReactElement => {
  const [selected, setSelected] = useState<Sponsor | null>(
    props.sponsors.find((s) => s.name === props.assign?.name) ?? null,
  );
  const handleSelectSponsor: SelectCallback = async (
    selected: string | null,
  ) => {
    const sponsor: Sponsor | undefined = selected
      ? props.sponsors.find((s) => s.index === parseInt(selected))
      : undefined;
    setSelected(sponsor ?? null);
    if (sponsor !== undefined) {
      props.onChangeSponsor(sponsor);
    }
  };

  const keyPressHandler = props.onKeyPress ?? (() => {});

  return (
    <Modal show={props.assign !== null} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Assign sponsor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-3 sponsorDropDown">
          <InputGroup.Prepend>
            <InputGroup.Text id={'fieldSponsor'} className="label">
              Sponsor:
            </InputGroup.Text>
          </InputGroup.Prepend>
          <DropdownButton
            id={'selectSponsorDropdown'}
            as={ButtonGroup}
            variant="info"
            onSelect={handleSelectSponsor}
            title={selected !== null ? selected.name : 'Select one'}
          >
            {props.sponsors.map((sponsor) => (
              <Dropdown.Item
                key={sponsor.index}
                eventKey={'' + sponsor.index}
                active={sponsor.index === selected?.index}
              >
                <Dropdown.ItemText className="text-sub">
                  {sponsor.name}
                </Dropdown.ItemText>
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </InputGroup>
        <InputField
          className="mb-3"
          label={'Donation:'}
          value={props.assign?.donation ?? ''}
          placeholder={'1000€'}
          onChange={props.onChangeDonation}
          onKeyPress={keyPressHandler}
        />
        <InputField
          className="mb-3"
          label={'Seats:'}
          fieldType={'number'}
          value={props.assign?.numberOfSeats ?? 0}
          onChange={(event) =>
            props.onChangeNumberOfSeats(event.target.valueAsNumber)
          }
          onKeyPress={keyPressHandler}
        />
        <InputGroup className={'mb-3'}>
          <InputGroup.Prepend>
            <InputGroup.Text id={'payment'} className="label">
              {'Payment received?:'}
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            checked={props.assign.paymentReceived || undefined}
            type={'checkbox'}
            aria-label={'Payment received?:'}
            aria-describedby={'payment'}
            onChange={props.onChangePaymentReceived}
          />
        </InputGroup>
        <InputGroup className={'mb-3'}>
          <InputGroup.Prepend>
            <InputGroup.Text id={'show-logo'} className="label">
              {'Show logo on website?:'}
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            checked={props.assign.showLogo || undefined}
            type={'checkbox'}
            aria-label={'Show logo on website?:'}
            aria-describedby={'show-logo'}
            onChange={props.onChangeShowLogoReceived}
          />
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onClose}>
          Close
        </Button>
        <Button variant="info" onClick={props.onSave}>
          Save changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
