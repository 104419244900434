import axios from 'axios';
import { Conference, ConferenceState, UUID } from '../types';
import { config } from '../../config';

const conferencesApi: string = config.apiEndpoints.conferences;
const conferencesEndpoint: string = `${conferencesApi}/conferences`;

export type ConferencesApi = {
  getConferences: () => Promise<Conference[]>;
  deleteConference: (conference: Conference) => Promise<void>;
  createConference: (conference: Conference) => Promise<void>;
  updateConference: (conference: Conference) => Promise<void>;
};

const findAllConferences = async (): Promise<Conference[]> => {
  return (await axios.get(conferencesEndpoint)).data;
};

const putConference = async (created: Conference): Promise<void> => {
  await axios.put(conferencesEndpoint, created);
};

const deleteConferenceById = async (id: UUID): Promise<void> => {
  await axios.delete(`${conferencesEndpoint}/${id}`);
};

export class Conferences implements ConferencesApi {
  private _conferences: Conference[];

  public constructor() {
    this._conferences = [];
  }

  public createConference = async (conf: Conference): Promise<void> => {
    await putConference(conf);
  };

  public updateConference = async (updated: Conference): Promise<void> => {
    const index: number | undefined = updated.index;
    if (index !== undefined) {
      const toPut: Conference = { ...updated };
      toPut.index = undefined;
      delete toPut.index;
      await putConference(toPut);
    }
  };

  public deleteConference = async (conf: Conference): Promise<void> => {
    if (conf.state === ConferenceState.PREPARATION) {
      await deleteConferenceById(conf.id);
    } else {
      console.error(
        'A conference can only be deleted while in the PREPARATION state.',
      );
    }
  };

  public getConferences = async (): Promise<Conference[]> => {
    this._conferences = await findAllConferences();
    return this._conferences.map((s, i) => ({ ...s, index: i }));
  };
}
