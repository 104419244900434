import { v4 as uuid } from 'uuid';
import axios from 'axios';
import { Sponsor, SponsorSlot, UUID } from '../types';
import { config } from '../../config';

const sponsorsDataApi: string = config.apiEndpoints.sponsors;
const sponsorsEndpoint: string = `${sponsorsDataApi}/sponsors`;
const sponsorsSlotsApi: string = config.apiEndpoints.slots;
const slotsEndpoint: string = `${sponsorsSlotsApi}/slots`;

export type SponsorsApi = {
  getSponsors: () => Promise<Sponsor[]>;
  deleteSponsor: (index?: number) => Promise<void>;
  createSponsor: () => Promise<Sponsor>;
  updateSponsor: (sponsor: Sponsor) => Promise<void>;
  getSponsorSlots: (conference: string) => Promise<SponsorSlot[]>;
  assignSponsorSlot: (slot: SponsorSlot) => Promise<void>;
  updateSponsorSlot: (slot: SponsorSlot) => Promise<void>;
  deleteSponsorSlot: (conference: string, index?: number) => Promise<void>;
  upload: (file: File) => Promise<void>;
};
const findAllSponsors = async (): Promise<Sponsor[]> => {
  return (await axios.get(sponsorsEndpoint)).data;
};

const putSponsor = async (created: Sponsor): Promise<void> => {
  await axios.put(sponsorsEndpoint, created);
};

const deleteSponsorById = async (id: UUID): Promise<void> => {
  await axios.delete(`${sponsorsEndpoint}/${id}`);
};

const findAllSponsorSlots = async (
  conference: string,
): Promise<SponsorSlot[]> => {
  return (await axios.get(`${slotsEndpoint}/${conference}`)).data;
};

const putSponsorSlot = async (updated: SponsorSlot): Promise<void> => {
  await axios.put(slotsEndpoint, updated);
};

const deleteSponsorSlotById = async (
  conference: string,
  id: UUID,
): Promise<void> => {
  await axios.delete(`${slotsEndpoint}/${conference}/${id}`);
};

const uploadFile = (file: File): Promise<void> => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      async () => {
        const data = {
          filename: file.name,
          mimetype: file.type,
          // convert image file to base64 string
          file: reader.result,
        };
        await axios.put(`${sponsorsEndpoint}/logo`, data);
        resolve();
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  });
};

export class Sponsors implements SponsorsApi {
  private _sponsors: Sponsor[];
  private _sponsorSlots: SponsorSlot[];

  public constructor() {
    this._sponsors = [];
    this._sponsorSlots = [];
  }

  public createSponsor = async (): Promise<Sponsor> => {
    const created: Sponsor = {
      id: uuid(),
      name: '',
      url: '',
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      logo: '',
    };
    await putSponsor(created);
    created.index = this._sponsors.length;
    return created;
  };

  public updateSponsor = async (updated: Sponsor): Promise<void> => {
    const index: number | undefined = updated.index;
    if (index !== undefined) {
      const toPut: Sponsor = { ...updated };
      toPut.index = undefined;
      delete toPut.index;
      await putSponsor(toPut);
    }
  };

  public deleteSponsor = async (index?: number): Promise<void> => {
    if (index !== undefined) {
      const id = this._sponsors[index].id;
      await deleteSponsorById(id);
    }
  };

  public getSponsors = async (): Promise<Sponsor[]> => {
    this._sponsors = await findAllSponsors();
    return this._sponsors.map((s, i) => ({ ...s, index: i }));
  };

  public getSponsorSlots = async (
    conference: string,
  ): Promise<SponsorSlot[]> => {
    this._sponsorSlots = await findAllSponsorSlots(conference);
    return this._sponsorSlots.map((s, i) => ({ ...s, index: i }));
  };

  public assignSponsorSlot = async (slot: SponsorSlot): Promise<void> => {
    await putSponsorSlot(slot);
    slot.index = this._sponsorSlots.length;
  };

  public updateSponsorSlot = async (slot: SponsorSlot): Promise<void> => {
    if (slot.index !== undefined) {
      const toPut = { ...slot };
      toPut.index = undefined;
      delete toPut.index;
      await putSponsorSlot(toPut);
    }
  };

  public deleteSponsorSlot = async (
    conference: string,
    index?: number,
  ): Promise<void> => {
    if (index !== undefined) {
      const id = this._sponsorSlots[index].id;
      await deleteSponsorSlotById(conference, id);
    }
  };

  public upload = async (file: File): Promise<void> => {
    await uploadFile(file);
  };
}
