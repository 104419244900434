import { Button, Table } from 'react-bootstrap';
import { PagingRow } from './PagingRow';
import React, { useCallback, useState } from 'react';
import { SelectCallback, TableRow } from '../main/types';

export type RowMapper = (
  row: TableRow,
  index?: number,
  array?: TableRow[],
) => JSX.Element;

type PagingTableProps = React.PropsWithChildren<any> & {
  rows: any[];
  className: string;
  listHeader: JSX.Element;
  tableHeader: JSX.Element;
  rowMapper: RowMapper;
  emptyRow: JSX.Element;
  lockProfiles?: () => void;
  exportXml?: () => void;
  exportCsv?: () => void;
  exportStripe?: () => void;
};
export const PagingTable = (props: PagingTableProps): React.ReactElement => {
  const [pageSize, setPageSize] = useState(500);
  const [page, setPage] = useState(0);
  const handlePageSizeSelect: SelectCallback = (size: string | null) =>
    setPageSize(size ? parseInt(size) : 1);
  const handlePrevPage: () => void = () => setPage(page - 1);
  const handleNextPage: () => void = () => setPage(page + 1);
  const minPageItems: number = page * pageSize;
  const maxPageItems: number = (page + 1) * pageSize;
  const filterVisible = useCallback(
    () =>
      props.rows
        .map((row: TableRow, index: number) => ({ ...row, index }))
        .filter(
          ({ index }: TableRow) =>
            index !== undefined &&
            index >= minPageItems &&
            index < maxPageItems,
        ),
    [props.rows, minPageItems, maxPageItems],
  );

  const minItem: number = minPageItems + 1;
  const maxItem: number =
    maxPageItems < props.rows.length ? maxPageItems : props.rows.length;
  const maxPage: number = Math.ceil(props.rows.length / pageSize) - 1;

  const visible: TableRow[] = filterVisible();
  const headerClass = props.exportCsv ? 'col-6 mt-2' : 'col-6';
  return (
    <>
      <div className="row">
        <div className={props.className + ' card mt-4'}>
          <div className="card-header">
            <div className="row">
              <div className={headerClass}>{props.listHeader}</div>
              <div className={'col-6 text-right'}>
                {props.rows.length > 0 && props.lockProfiles && (
                  <Button
                    className="mr-1"
                    variant={'danger'}
                    onClick={props.lockProfiles}
                  >
                    Lock Profiles
                  </Button>
                )}
                {props.rows.length > 0 && props.exportXml && (
                  <Button
                    className="mr-1"
                    variant={'info'}
                    onClick={props.exportXml}
                  >
                    Export Badges XML
                  </Button>
                )}
                {props.rows.length > 0 && props.exportStripe && (
                  <Button
                    className="mr-1"
                    variant={'info'}
                    onClick={props.exportStripe}
                  >
                    Export Stripe JSON
                  </Button>
                )}
                {props.rows.length > 0 && props.exportCsv && (
                  <Button variant={'info'} onClick={props.exportCsv}>
                    Export to CSV
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="card-body p-4">
            <Table striped bordered hover className="m-0">
              <thead>{props.tableHeader}</thead>
              <tbody>
                {props.rows && props.rows.length > 0
                  ? visible.map(props.rowMapper)
                  : props.emptyRow}
              </tbody>
            </Table>
          </div>
          {props.rows.length > 0 && (
            <div className="card-footer">
              <PagingRow
                rows={props.rows}
                page={page}
                pageSize={pageSize}
                onPrevPage={handlePrevPage}
                maxPage={maxPage}
                onNextPage={handleNextPage}
                onSelect={handlePageSizeSelect}
                minItem={minItem}
                maxItem={maxItem}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
