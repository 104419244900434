import React from 'react';
import { Button, FormControl, Modal } from 'react-bootstrap';
import { InputField } from '../../form/InputField';
import { Sponsor } from '../../main/types';

type SponsorEditModalProps = {
  edit: Sponsor;
  onChangeLogo: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onUploadLogo: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeUrl: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeContactName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeContactEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeContactPhone: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: () => void;
  onClose: () => void;
  onSave: () => Promise<void>;
};
export const SponsorEditModal = (
  props: SponsorEditModalProps,
): React.ReactElement => {
  const keyPressHandler = props.onKeyPress ?? (() => {});
  return (
    <Modal show={props.edit !== null} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit sponsor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputField
          className="mb-3"
          label={'Sponsor Name / Company:'}
          value={props.edit?.name ?? ''}
          placeholder={'Sponsor name / company'}
          onChange={props.onChangeName}
          onKeyPress={keyPressHandler}
        />
        <InputField
          className="mb-3"
          label={'Website URL:'}
          value={props.edit?.url ?? ''}
          placeholder={'http://sponsor.com...'}
          onChange={props.onChangeUrl}
          onKeyPress={keyPressHandler}
        />
        <InputField
          className="mb-3"
          label={'Contact Name:'}
          value={props.edit?.contactName ?? ''}
          placeholder={'Contact name'}
          onChange={props.onChangeContactName}
          onKeyPress={keyPressHandler}
        />
        <InputField
          className="mb-3"
          label={'Contact Email:'}
          value={props.edit?.contactEmail ?? ''}
          placeholder={'Contact email'}
          onChange={props.onChangeContactEmail}
          onKeyPress={keyPressHandler}
        />
        <InputField
          className="mb-3"
          label={'Contact Phone:'}
          value={props.edit?.contactPhone ?? ''}
          placeholder={'Contact phone'}
          onChange={props.onChangeContactPhone}
          onKeyPress={keyPressHandler}
        />
        <InputField
          className="mb-3"
          label={'Logo:'}
          value={props.edit?.logo ?? ''}
          placeholder={'Logo filename'}
          onChange={props.onChangeLogo}
          onKeyPress={keyPressHandler}
        />
        <FormControl
          value=""
          onChange={props.onUploadLogo}
          as="input"
          type={'file'}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.onClose}>
          Close
        </Button>
        <Button variant="info" onClick={props.onSave}>
          Save changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
