import { Button } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import React from 'react';
import { Template } from '../../main/types';

type NewsletterTemplatesListRowProps = {
  template: Template;
  url: string;
  onEditClick: () => void;
  onDeleteClick: () => void;
};
export const NewsletterTemplatesListRow = (
  props: NewsletterTemplatesListRowProps,
): React.ReactElement => (
  <tr>
    <td>{(props.template.index || 0) + 1}</td>
    <td className="newsletter-title">{props.template.title}</td>
    <td className="newsletter-subject">{props.template.subject}</td>
    <td className="newsletter-text">{props.template.text}</td>
    <td className="text-center align-baseline">
      <Button
        className="p-0 mr-1"
        variant="none"
        title={`Edit template: ${props.template.title}`}
        onClick={props.onEditClick}
      >
        <MdEdit />
      </Button>
      <Button
        className="p-0 ml-1"
        variant="none"
        title={`Delete template: ${props.template.title}`}
        onClick={props.onDeleteClick}
      >
        <FaTrash />
      </Button>
    </td>
  </tr>
);

export const NewsletterTemplatesEmptyListRow = (): React.ReactElement => (
  <tr>
    <td className="text-center" colSpan={5}>
      There are currently no available templates.
    </td>
  </tr>
);
