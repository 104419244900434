import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useEvents } from '../main/EventsProvider';
import { LoggableEvent } from '../main/types';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import moment from 'moment';
import { FaCog } from 'react-icons/fa';
import 'react-vertical-timeline-component/style.min.css';
import './OperationsTimeline.scss';

export const OperationsTimeline = (): React.ReactElement => {
  const location = useLocation();
  const eventsContext = useEvents();
  const [events, setEvents] = useState<LoggableEvent[]>([]);
  const parts: string[] = location.pathname.split('/');
  const correlationId: string = parts[parts.length - 1] as string;

  useEffect(() => {
    if (events.length === 0) {
      eventsContext
        .getEventsByCorrelationId(correlationId)
        .then((result) =>
          setEvents(
            [...result].sort((a, b) =>
              a.timestamp > b.timestamp
                ? 1
                : a.timestamp < b.timestamp
                  ? -1
                  : 0,
            ),
          ),
        );
    }
  }, [eventsContext, events, correlationId]);

  const filterPayloadAttributes = (e: LoggableEvent) => {
    const eventAttributes: string[] = [
      'timestamp',
      'correlationId',
      'expireAt',
      'type',
    ];
    return Object.keys(e).filter((k) => eventAttributes.indexOf(k) < 0);
  };

  return (
    <div>
      <VerticalTimeline>
        {events.length > 0 &&
          events.map((e) => (
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              contentArrowStyle={{
                borderRight: '7px solid  rgb(33, 150, 243)',
              }}
              date={moment(e.timestamp).format('YYYY-MM-DD - HH:mm:ss')}
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              icon={<FaCog />}
            >
              <h3 className="vertical-timeline-element-title">{e.type}</h3>
              <h4 className="vertical-timeline-element-subtitle">
                {e.correlationId}
              </h4>
              <ul className="eventbody">
                {filterPayloadAttributes(e).map((k) => (
                  <li>
                    {k}:<br />
                    {JSON.stringify(e[k])}
                  </li>
                ))}
              </ul>
            </VerticalTimelineElement>
          ))}
      </VerticalTimeline>
    </div>
  );
};
