import { FormControl, InputGroup } from 'react-bootstrap';
import React from 'react';
import { ChangeHandler, KeyPressHandler } from './events';
import './InputField.scss';

export type InputFieldType = 'text' | 'password' | 'file' | 'number';

type InputFieldProps = {
  label: string;
  disabled?: boolean;
  value: string | number;
  placeholder?: string;
  className?: string;
  fieldType?: InputFieldType;
  onKeyPress?: KeyPressHandler<HTMLInputElement>;
  onChange?: ChangeHandler<HTMLInputElement>;
};

export const InputField = (props: InputFieldProps): React.ReactElement => {
  const {
    label,
    disabled,
    value,
    placeholder,
    className,
    fieldType = 'text',
    onChange,
    onKeyPress,
  } = props;
  const labelName = 'field-' + label.replace(' ', '').toLowerCase();
  return (
    <InputGroup className={className}>
      <InputGroup.Prepend>
        <InputGroup.Text id={labelName} className="label">
          {label}
        </InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl
        value={value}
        onKeyDown={onKeyPress}
        onChange={onChange}
        as="input"
        disabled={disabled}
        type={fieldType}
        placeholder={placeholder}
        aria-label={label}
        aria-describedby={labelName}
      />
    </InputGroup>
  );
};
