import React from 'react';
import { Button } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import { SponsorSlot } from '../../main/types';
import { config } from '../../config';

type Props = {
  sponsor: SponsorSlot;
  onEditClick: () => void;
  onDeleteClick: () => void;
};
export const SponsorSlotsListRow = (props: Props): React.ReactElement => {
  const sponsor = props.sponsor;
  return (
    <tr>
      <td>{(sponsor.index || 0) + 1}</td>
      <td>
        <img src={`${config.logoPath}/logos/${sponsor.logo}`} alt="logo" />
      </td>
      <td>{sponsor.name}</td>
      <td>{sponsor.donation}</td>
      <td>{sponsor.numberOfSeats}</td>
      <td>{sponsor.paymentReceived ? 'yes' : 'no'}</td>
      <td>{sponsor.showLogo ? 'yes' : 'no'}</td>
      <td className="text-center align-baseline">
        <Button
          className="p-0 ml-1"
          variant="none"
          title={`Edit ${sponsor.name}`}
          onClick={props.onEditClick}
        >
          <MdEdit />
        </Button>
        <Button
          className="p-0 ml-1"
          variant="none"
          title={`Delete ${sponsor.name}`}
          onClick={props.onDeleteClick}
        >
          <FaTrash />
        </Button>
      </td>
    </tr>
  );
};
export const EmptySponsorSlotsListRow = (): React.ReactElement => (
  <tr>
    <td className="text-center" colSpan={8}>
      Sponsors have not yet been assigned to slots.
    </td>
  </tr>
);
