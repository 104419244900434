import React, { useEffect, useState } from 'react';
import './NewsletterSubscribersList.scss';
import { PagingTable, RowMapper } from '../../table/PagingTable';
import {
  EmptyNewsletterSubscribersListRow,
  NewsletterSubscribersListRow,
} from './NewsletterSubscribersListRow';
import { useNewsletter } from '../../main/NewsletterProvider';
import { Subscriber } from '../../main/types';

const NewsletterSubscribersTableHeader = (): React.ReactElement => (
  <tr>
    <th>#</th>
    <th>Name</th>
    <th>Email</th>
    <th>Actions</th>
  </tr>
);

const NewsletterSubscribersHeader = (): React.ReactElement => (
  <div>
    <h5 className="mb-0">Newsletter subscribers</h5>
  </div>
);

export const NewsletterSubscribersList = (): React.ReactElement => {
  const newsletter = useNewsletter();
  const [subscribers, setSubscribers] = useState<Subscriber[]>([]);

  useEffect(() => {
    newsletter.getSubscribers().then((subs) => {
      setSubscribers(subs);
    });
  }, [newsletter]);

  const deleteSubscriber = async (index?: number): Promise<void> => {
    if (index !== undefined) {
      await newsletter.deleteSubscriber(index);
      setSubscribers(await newsletter.getSubscribers());
    }
  };

  const subscribersMapper: RowMapper = (row): React.ReactElement => (
    <NewsletterSubscribersListRow
      key={row.index}
      subscriber={row as Subscriber}
      onDelete={(index?: number) => deleteSubscriber(index)}
    />
  );

  return (
    <PagingTable
      rows={subscribers}
      className={'templates'}
      listHeader={<NewsletterSubscribersHeader />}
      tableHeader={<NewsletterSubscribersTableHeader />}
      emptyRow={<EmptyNewsletterSubscribersListRow />}
      rowMapper={subscribersMapper}
    />
  );
};
