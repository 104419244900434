import { Form, InputGroup } from 'react-bootstrap';
import React from 'react';
import { ChangeHandler } from './events';

type DateFieldProps = {
  className?: string;
  label: string;
  disabled?: boolean;
  value: string;
  onChange: ChangeHandler<HTMLInputElement>;
};

export const DateField = ({
  label,
  className,
  disabled,
  value,
  onChange,
}: DateFieldProps): React.ReactElement => {
  const labelName = 'field-' + label.replace(' ', '').toLowerCase();
  return (
    <InputGroup className={className}>
      <InputGroup.Prepend>
        <InputGroup.Text id={labelName} className="label">
          {label}
        </InputGroup.Text>
      </InputGroup.Prepend>
      <Form.Control
        disabled={disabled}
        type="date"
        name={labelName}
        value={value}
        onChange={onChange}
        aria-label={label}
        aria-describedby={labelName}
      />
    </InputGroup>
  );
};
