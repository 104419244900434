import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
import { SubscribersApi } from './api/subscribers';
import { TemplatesApi } from './api/templates';
import { NewsletterApi } from './api/newsletter';
import { HistoryEntry, Subscriber, Template } from './types';

type NewsletterContext = {
  createTemplate: () => Promise<Template>;
  deleteSubscriber: (index?: number) => Promise<void>;
  getHistory: () => Promise<HistoryEntry[]>;
  getSubscribers: () => Promise<Subscriber[]>;
  getTemplates: () => Promise<Template[]>;
  deleteTemplate: (index: number) => Promise<void>;
  selectTemplate: (index: number) => Promise<void>;
  selectedTemplate: number;
  send: (title: string) => Promise<void>;
  updateTemplate: (template: Template) => Promise<void>;
};
const DEFAULT_CONTEXT: NewsletterContext = {
  createTemplate: async () => {
    console.log('not creating template');
    return { title: '', subject: '', htmlText: '', text: '' };
  },
  deleteSubscriber: async (index?: number) =>
    console.log('not deleting:', index),
  deleteTemplate: async (index: number) => console.log('not deleting:', index),
  getHistory: async () => {
    console.log('not getting history');
    return [];
  },
  getSubscribers: () => {
    console.log('not getting subscribers');
    return Promise.resolve([]);
  },
  getTemplates: async () => {
    console.log('not getting templates');
    return Promise.resolve([]);
  },
  selectTemplate: async (index: number) => console.log('not selecting:', index),
  selectedTemplate: -1,
  send: async (title: string) => console.log('not sending:', title),
  updateTemplate: async (template: Template) =>
    console.log('not updating', template),
};
const newsletterContext = createContext(DEFAULT_CONTEXT);
export const useNewsletter = (): NewsletterContext =>
  useContext(newsletterContext);

const useProvideNewsletter = (): {
  selectedTemplate: number;
  selectTemplate: (index: number) => Promise<void>;
} => {
  const [selectedTemplate, setSelectedTemplate] = useState(-1);

  const selectTemplate = async (index: number): Promise<void> => {
    setSelectedTemplate(index);
  };

  return {
    selectedTemplate,
    selectTemplate,
  };
};

type ProvideNewsletterProps = {
  subscribersApi: SubscribersApi;
  templatesApi: TemplatesApi;
  newsletterApi: NewsletterApi;
};

export const NewsletterProvider = ({
  subscribersApi,
  templatesApi,
  newsletterApi,
  children,
}: PropsWithChildren<ProvideNewsletterProps>): React.ReactElement => {
  const newsletter: NewsletterContext = {
    ...useProvideNewsletter(),
    deleteTemplate: templatesApi.deleteTemplate,
    createTemplate: templatesApi.createTemplate,
    deleteSubscriber: subscribersApi.deleteSubscriber,
    getHistory: newsletterApi.getHistory,
    getSubscribers: subscribersApi.getSubscribers,
    getTemplates: templatesApi.getTemplates,
    send: newsletterApi.send,
    updateTemplate: templatesApi.updateTemplate,
  };
  return (
    <newsletterContext.Provider value={newsletter}>
      {children}
    </newsletterContext.Provider>
  );
};
