import React, { useEffect, useState } from 'react';
import './App.scss';
import { Amplify } from 'aws-amplify';
import { LoginScreen } from '../login/LoginScreen';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { NewsletterScreen } from '../newsletter/NewsletterScreen';
import { EventsScreen } from '../events/EventsScreen';
import { ConferenceProvider } from './ConferenceProvider';
import { AuthProvider, useAuth } from './AuthProvider';
import { NewsletterProvider } from './NewsletterProvider';
import { SponsorsScreen } from '../sponsors/SponsorsScreen';
import { SponsorsProvider } from './SponsorsProvider';
import { Subscribers } from './api/subscribers';
import { Sponsors } from './api/sponsors';
import { Templates } from './api/templates';
import { Newsletter } from './api/newsletter';
import { Conferences } from './api/conferences';
import { ApplicantsProvider } from './ApplicantsProvider';
import { Applicants } from './api/applicants';
import { Attendees } from './api/attendees';
import { AttendeesProvider } from './AttendeesProvider';
import { BookingImpl } from './api/booking';
import { BookingProvider } from './BookingProvider';
import { config } from '../config';
import { OperationsScreen } from '../operations/OperationsScreen';
import { EventsProvider } from './EventsProvider';
import { Events } from './api/events';
import { Invoices } from './api/invoices';

Amplify.configure({
  Auth: {
    ...config.auth,
  },
});

const PrivateRoute = ({ children, path }: any): React.ReactElement => {
  const auth = useAuth();
  const [authorized, setAuthorized] = useState(false);
  useEffect(() => {
    if (auth.user !== undefined && auth.user.trim() !== '') setAuthorized(true);
  }, [auth.user, setAuthorized]);

  return (
    <Route
      path={path}
      render={() =>
        authorized ? (
          children
        ) : (
          <div>
            <h1>Unauthorized</h1>
          </div>
        )
      }
    />
  );
};

const NoMatch = (): React.ReactElement => {
  const location = useLocation();
  return (
    <div>
      <h3>No Match for route {location.pathname}.</h3>
    </div>
  );
};

const App = (): React.ReactElement => {
  return (
    <AuthProvider>
      <ConferenceProvider conferencesApi={new Conferences()}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect to="/events" />
            </Route>
            <Route path="/login">
              <LoginScreen />
            </Route>
            <PrivateRoute path="/operations">
              <EventsProvider eventsApi={new Events()}>
                <OperationsScreen />
              </EventsProvider>
            </PrivateRoute>
            <PrivateRoute path="/newsletter">
              <NewsletterProvider
                subscribersApi={new Subscribers()}
                templatesApi={new Templates()}
                newsletterApi={new Newsletter()}
              >
                <NewsletterScreen />
              </NewsletterProvider>
            </PrivateRoute>
            <SponsorsProvider sponsorsApi={new Sponsors()}>
              <PrivateRoute path="/sponsors">
                <SponsorsScreen />
              </PrivateRoute>
              <PrivateRoute path="/events">
                <BookingProvider bookingApi={new BookingImpl()}>
                  <ApplicantsProvider applicantsApi={new Applicants()}>
                    <AttendeesProvider
                      attendeesApi={new Attendees()}
                      invoicesApi={new Invoices()}
                    >
                      <EventsScreen />
                    </AttendeesProvider>
                  </ApplicantsProvider>
                </BookingProvider>
              </PrivateRoute>
            </SponsorsProvider>
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </Router>
      </ConferenceProvider>
    </AuthProvider>
  );
};

export default App;
