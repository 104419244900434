import React from 'react';
import { Button } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import { MdEdit, MdMail } from 'react-icons/md';
import { config } from '../../config';
import { Sponsor } from '../../main/types';

type SponsorListRowProps = {
  sponsor: Sponsor;
  onEditClick: () => void;
  onDeleteClick: () => void;
};
export const SponsorsListRow = (
  props: SponsorListRowProps,
): React.ReactElement => {
  const sponsor = props.sponsor;
  return (
    <tr>
      <td>{(sponsor.index || 0) + 1}</td>
      <td>
        <img src={`${config.logoPath}/logos/${sponsor.logo}`} alt="logo" />
      </td>
      <td>{sponsor.name}</td>
      <td>{sponsor.contactName || '-n/a-'}</td>
      <td>{sponsor.contactEmail || '-n/a-'}</td>
      <td>{sponsor.contactPhone || '-n/a-'}</td>
      <td className="text-center align-baseline">
        <Button
          className="p-0 mr-1"
          variant="none"
          role="link"
          href={`mailto:${sponsor.contactEmail}`}
          title={`Send an email to ${sponsor.contactName}`}
        >
          <MdMail />
        </Button>
        <Button
          className="p-0 mr-1"
          variant="none"
          title={`Edit ${sponsor.name}`}
          onClick={props.onEditClick}
        >
          <MdEdit />
        </Button>
        <Button
          className="p-0 ml-1"
          variant="none"
          title={`Delete ${sponsor.name}`}
          onClick={props.onDeleteClick}
        >
          <FaTrash />
        </Button>
      </td>
    </tr>
  );
};
export const EmptySponsorsListRow = (): React.ReactElement => (
  <tr>
    <td className="text-center" colSpan={7}>
      There are currently no active sponsors.
    </td>
  </tr>
);
