import React from 'react';
import { Button } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import { MdMail } from 'react-icons/md';
import { Subscriber } from '../../main/types';

type NewsLetterSubscriberListRowProps = {
  subscriber: Subscriber;
  onDelete: (index?: number) => void;
};
export const NewsletterSubscribersListRow = (
  props: NewsLetterSubscriberListRowProps,
): React.ReactElement => {
  const subscriber = props.subscriber;
  return (
    <tr>
      <td>{(subscriber.index || 0) + 1}</td>
      <td>{subscriber.name}</td>
      <td>{subscriber.email}</td>
      <td className="text-center align-baseline">
        <Button
          className="p-0 mr-1"
          variant="none"
          role="link"
          href={`mailto:${subscriber.email}`}
          title={`Send an email to ${subscriber.name}`}
        >
          <MdMail />
        </Button>
        <Button
          className="p-0 ml-1"
          variant="none"
          title={`Unsubscribe ${subscriber.name}`}
          onClick={() => props.onDelete(subscriber.index)}
        >
          <FaTrash />
        </Button>
      </td>
    </tr>
  );
};
export const EmptyNewsletterSubscribersListRow = (): React.ReactElement => (
  <tr>
    <td className="text-center" colSpan={4}>
      There are currently no active subscriptions.
    </td>
  </tr>
);
