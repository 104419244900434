import { Form, InputGroup } from 'react-bootstrap';
import React, { KeyboardEventHandler } from 'react';
import { ChangeHandler } from './events';
import './InputField.scss';

type TextAreaProps = {
  label: string;
  value: string;
  placeholder: string;
  className?: string;
  rows?: number;
  onKeyPress?: KeyboardEventHandler<HTMLTextAreaElement>;
  onChange: ChangeHandler<HTMLTextAreaElement>;
};

export const TextArea = (props: TextAreaProps): React.ReactElement => {
  const {
    label,
    value,
    placeholder,
    className,
    rows = 5,
    onChange,
    onKeyPress,
  } = props;
  const labelName = 'field-' + label.replace(' ', '').toLowerCase();
  return (
    <InputGroup className={className}>
      <InputGroup.Prepend>
        <InputGroup.Text id={labelName} className="label">
          {label}
        </InputGroup.Text>
      </InputGroup.Prepend>
      <Form.Control
        value={value}
        onKeyDown={onKeyPress}
        onChange={onChange}
        as="textarea"
        rows={rows}
        placeholder={placeholder}
        aria-label={label}
        aria-describedby={labelName}
      />
    </InputGroup>
  );
};
