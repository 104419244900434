export const UPPERCASE: RegExp = new RegExp(/[A-Z]/);

export const deCamelCase = (name: string): string => {
  let pretty = '';
  for (let i = 0; i < name.length; i++) {
    if (i > 0 && UPPERCASE.test(name.charAt(i))) {
      pretty = pretty.concat(' ' + name.charAt(i));
    } else {
      pretty = pretty.concat(name.charAt(i));
    }
  }
  return pretty;
};

export const prettifyCommand = (name: string): string => {
  const withoutCommand: string = name.substring(0, name.lastIndexOf('Command'));
  return deCamelCase(withoutCommand);
};
