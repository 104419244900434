import React, { FormEvent, KeyboardEventHandler } from 'react';
import { Button, Form } from 'react-bootstrap';
import { ChangeHandler, FormSubmitHandler } from '../form/events';
import { InputField } from '../form/InputField';
import './LoginCard.scss';

type UsernameFieldProps = {
  className: string;
  value: string;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
  onChange: ChangeHandler<HTMLInputElement>;
};
const UsernameField = (props: UsernameFieldProps): React.ReactElement => (
  <InputField
    className={props.className}
    value={props.value}
    label="Username"
    placeholder="Username"
    onChange={props.onChange}
  />
);

type PasswordFieldProps = {
  value: string;
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
  onChange: ChangeHandler<HTMLInputElement>;
};
const PasswordField = (props: PasswordFieldProps): React.ReactElement => (
  <InputField
    className="mb-0"
    value={props.value}
    label="Password"
    placeholder="••••••••"
    fieldType="password"
    onKeyPress={props.onKeyPress}
    onChange={props.onChange}
  />
);

type Props = {
  error: boolean;
  username: string;
  password: string;
  onClear: () => void;
  onUsernameChange: ChangeHandler<HTMLInputElement>;
  onPasswordChange: ChangeHandler<HTMLInputElement>;
  onSubmit: FormSubmitHandler;
};

export const LoginCard = (props: Props): React.ReactElement => {
  const errorClass = props.error ? 'error border-danger ' : 'border-info ';
  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = async (
    event,
  ) => {
    event.persist();
    if (event.key === 'Enter') {
      await props.onSubmit({} as unknown as FormEvent);
    }
  };
  return (
    <div className={errorClass + 'card login shadow-lg'}>
      <Form>
        <div className="card-header border-info bg-info b-0">
          <img
            className="logo"
            alt="SoCraTes logo"
            src="https://raw.githubusercontent.com/softwerkskammer/softwerkskammer-logos/master/SoCraTes/2020/SoCraTesWappen_2020.png"
          />
          <h5 className="login-header text-dark mb-0">Please log in:</h5>
        </div>
        <div className="card-body">
          <UsernameField
            className="mb-3"
            value={props.username}
            onChange={props.onUsernameChange}
            onKeyPress={handleKeyPress}
          />
          <PasswordField
            value={props.password}
            onChange={props.onPasswordChange}
            onKeyPress={handleKeyPress}
          />
        </div>
        <div className="card-footer text-right">
          <Button
            onClick={props.onClear}
            className="btn-outline-dark btn-light mr-2"
          >
            Clear
          </Button>
          <Button onClick={props.onSubmit} className="btn-info">
            Login
          </Button>
        </div>
      </Form>
    </div>
  );
};
