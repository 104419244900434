import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import './NewsletterTemplatesList.scss';
import { useLocation } from 'react-router-dom';
import { PagingTable, RowMapper } from '../../table/PagingTable';
import {
  NewsletterTemplatesEmptyListRow,
  NewsletterTemplatesListRow,
} from './NewsletterTemplatesListRow';
import { useNewsletter } from '../../main/NewsletterProvider';
import { NewsletterTemplateEditModal } from './NewsletterTemplateEditModal';
import { TableRow, Template } from '../../main/types';

const NewsletterTemplatesListHeader = (props: {
  title: string;
  onCreateClick: () => void;
}): React.ReactElement => (
  <div className={'row'}>
    <div className={'col text-left'}>
      <h5 className="mb-0">{props.title}</h5>
    </div>
    <div className={'col text-right'}>
      <Button
        className="p-1 text-sub"
        variant={'info'}
        onClick={props.onCreateClick}
      >
        Create
      </Button>
    </div>
  </div>
);

const NewsletterTemplatesTableHeader = (): React.ReactElement => (
  <tr>
    <th>#</th>
    <th>Title</th>
    <th>Subject</th>
    <th>Text</th>
    <th>Actions</th>
  </tr>
);

export const NewsletterTemplatesList = (): React.ReactElement => {
  const [edit, setEdit] = useState(null as unknown as Template);
  const newsletter = useNewsletter();
  const [templates, setTemplates] = useState<Template[]>([]);
  const { pathname: url } = useLocation();

  useEffect(() => {
    newsletter.getTemplates().then((t) => setTemplates(t));
  }, [newsletter]);

  const createTemplate = async (): Promise<void> => {
    const created = await newsletter.createTemplate();
    setTemplates(await newsletter.getTemplates());
    setEdit(created);
  };

  const editTemplate = async (index?: number): Promise<void> => {
    if (index !== undefined) {
      setEdit({ ...templates[index], index });
    }
  };

  const deleteTemplate = async (index?: number): Promise<void> => {
    if (index !== undefined) {
      await newsletter.deleteTemplate(index);
      setTemplates(await newsletter.getTemplates());
    }
  };

  const handleChangeTitle = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setEdit({ ...edit, title: event?.target?.value ?? '' });
  };

  const handleChangeSubject = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setEdit({ ...edit, subject: event?.target?.value ?? '' });
  };

  const handleChangeText = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    event.persist();
    setEdit({ ...edit, text: event?.target?.value ?? '' });
  };

  const handleChangeHtmlText = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    event.persist();
    setEdit({ ...edit, htmlText: event?.target?.value ?? '' });
  };

  const handleClose = (): void => {
    setEdit(null as unknown as Template);
  };

  const handleSave = async (): Promise<void> => {
    if (edit.index !== undefined) {
      await newsletter.updateTemplate(edit);
      setTemplates(await newsletter.getTemplates());
    }
    setEdit(null as unknown as Template);
  };

  const templatesMapper: RowMapper = (row: TableRow): React.ReactElement => (
    <NewsletterTemplatesListRow
      key={row.index}
      template={row as Template}
      url={url}
      onEditClick={() => editTemplate(row.index)}
      onDeleteClick={() => deleteTemplate(row.index)}
    />
  );
  return (
    <div>
      <PagingTable
        rows={templates}
        className={'templates'}
        listHeader={
          <NewsletterTemplatesListHeader
            title="Newsletter templates"
            onCreateClick={createTemplate}
          />
        }
        tableHeader={<NewsletterTemplatesTableHeader />}
        emptyRow={<NewsletterTemplatesEmptyListRow />}
        rowMapper={templatesMapper}
      />
      <NewsletterTemplateEditModal
        edit={edit}
        onChangeTitle={handleChangeTitle}
        onChangeSubject={handleChangeSubject}
        onChangeHtmlText={handleChangeHtmlText}
        onChangeText={handleChangeText}
        onClose={handleClose}
        onSave={handleSave}
      />
    </div>
  );
};
