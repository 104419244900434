import { Stats } from '../types';
import axios, { AxiosResponse } from 'axios';
import { config } from '../../config';
import {
  InternalErrorException,
  NetworkException,
  NotFoundException,
} from './error';

export interface BookingApi {
  getStats: (conference: string) => Promise<Stats>;
}

const bookingApi: string = config.apiEndpoints.booking;
const bookingEndpoint: string = `${bookingApi}/booking`;

const getBooking = async (conference: string): Promise<AxiosResponse | void> =>
  await axios.get(bookingEndpoint + '/' + conference).catch((e) => {
    console.error('Error while getting stats:', e);
    if (e.message.endsWith('404')) {
      throw new NotFoundException();
    } else if (e.message.endsWith('500')) {
      throw new InternalErrorException();
    } else if (e.message === 'Network Error') {
      throw new NetworkException();
    }
  });
export class BookingImpl implements BookingApi {
  public async getStats(conference: string): Promise<Stats> {
    const result: AxiosResponse | undefined = (await getBooking(
      conference,
    )) as AxiosResponse;
    return result?.data;
  }
}
