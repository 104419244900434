import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import React, { useRef } from 'react';
import { FaCheckCircle } from 'react-icons/fa';

export const normalized = (str?: string): string =>
  str?.trim().toLowerCase() ?? '';
type DiversityStatusProps = {
  diversity: string;
};
export const DiversityStatus = ({
  diversity,
}: DiversityStatusProps): React.ReactElement => {
  const renderTooltip = (props: any): React.ReactElement => (
    <Tooltip id="button-tooltip" {...props}>
      {diversity}
    </Tooltip>
  );
  const target = useRef(null);
  // noinspection RequiredAttributes
  const icon =
    normalized(diversity) === 'yes' ? (
      <FaCheckCircle className={'text-success'} />
    ) : normalized(diversity) === 'no' ? (
      ''
    ) : (
      <OverlayTrigger
        // @ts-ignore  Type of target attribute is inaccurate
        target={target.current}
        placement="right"
        delay={{ show: 100, hide: 200 }}
        overlay={renderTooltip}
      >
        <div ref={target}>
          <FaCheckCircle className={'text-warning'} />
        </div>
      </OverlayTrigger>
    );
  return <>{icon}</>;
};
