import { Button, Modal } from 'react-bootstrap';
import React from 'react';

type Props = {
  visible?: boolean;
  onLockProfilesConfirm: () => void;
  onClose: () => void;
};

export const EventsAttendeesLockProfilesModal = ({
  visible,
  onLockProfilesConfirm,
  onClose,
}: Props): React.ReactElement => {
  return (
    <Modal show={visible === true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Lock Profiles</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          This will lock all current attendee profiles for edits - an action
          that can not be easily undone. Are you sure you want to proceed?
        </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onLockProfilesConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
