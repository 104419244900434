import React from 'react';
import { Navigation } from '../navigation/Navigation';
import { OperationsDashboard } from './OperationsDashboard';
import { OperationsSubnav } from './OperationsSubnav';
import { Redirect, Route, Switch } from 'react-router-dom';
import { OperationsProcesses } from './OperationsProcesses';
import { OperationsTimeline } from './OperationsTimeline';

export const OperationsScreen = (): React.ReactElement => {
  return (
    <div>
      <Navigation />
      <OperationsSubnav />
      <Switch>
        <Route exact path="/operations">
          <Redirect to="/operations/dashboard" />
        </Route>
        <Route exact path="/operations/dashboard">
          <OperationsDashboard />
        </Route>
        <Route exact path="/operations/processes">
          <OperationsProcesses />
        </Route>
        <Route path="/operations/timeline">
          <OperationsTimeline />
        </Route>
      </Switch>
    </div>
  );
};
