import { Conference, RoomType, RoomTypeName, Stats } from '../../main/types';
import { shallowCopy } from '../../common/copy';

export class ConferenceBeds {
  private stats: Stats;
  private conference: Conference;
  private totalAvailableBeds: Record<RoomTypeName, number>;
  private lotteryAvailableBeds: Record<RoomTypeName, number>;

  constructor(conference: Conference, stats: Stats) {
    this.conference = conference;
    this.stats = stats;
    this.totalAvailableBeds =
      conference.roomTypes?.reduce(
        (b, r) => {
          b[r.type] = this.initAvailableBedsForRoomType(r);
          return b;
        },
        {} as Record<RoomTypeName, number>,
      ) ?? ({} as Record<RoomTypeName, number>);
    this.lotteryAvailableBeds = shallowCopy(this.totalAvailableBeds);
  }

  getAvailableBedsTotal(): string {
    const totalBedCount: number = this.getTotalBedCount();
    const availableBedCount: number =
      totalBedCount - this.getBedCount(this.stats.bookedBeds);
    return availableBedCount + '/' + totalBedCount;
  }

  getLotteryBedsContingentForRoomType(r: RoomType): number {
    return this.lotteryAvailableBeds[r.type] ?? 0;
  }

  getLotteryBedsContingent(): Record<RoomTypeName, number> {
    return shallowCopy(this.lotteryAvailableBeds);
  }

  setLotteryBedsContingentForRoomType(r: RoomTypeName, count: number): void {
    let max: number = this.totalAvailableBeds[r];
    this.lotteryAvailableBeds[r] = count <= max ? count : max;
  }

  getAssignedBedsDiversity(): string {
    const totalBedCount: number = this.getTotalBedCount();
    const diversityBedsCount: number = Math.round(
      (totalBedCount * (this.conference.diversityRatio ?? 0)) / 100,
    );
    const assignedBeds: number = this.getBedCount(
      this.stats.bookedBedsDiversity,
    );
    return assignedBeds + '/' + diversityBedsCount;
  }

  private initAvailableBedsForRoomType(r: RoomType): number {
    if (this.stats.bookedBeds !== undefined) {
      return r.beds * r.count - (this.stats?.bookedBeds[r.type] ?? 0);
    } else {
      return 0;
    }
  }

  private getTotalBedCount(): number {
    return (
      this.conference.roomTypes?.reduce(
        (sum, r) => sum + r.beds * r.count,
        0,
      ) ?? 0
    );
  }

  private getBedCount(
    rooms: Record<RoomTypeName, number> = {} as Record<RoomTypeName, number>,
  ): number {
    return Object.values(rooms).reduce((sum, b) => sum + b, 0);
  }
}
