import { PagingTable, RowMapper } from '../../table/PagingTable';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  EmptySponsorSlotsListRow,
  SponsorSlotsListRow,
} from './SponsorSlotsListRow';
import { useSponsors } from '../../main/SponsorsProvider';
import { Button } from 'react-bootstrap';
import { SponsorAssignModal } from './SponsorAssignModal';
import { v4 as uuid } from 'uuid';
import './SponsorSlotsList.scss';
import { useConference } from '../../main/ConferenceProvider';
import { Conference, Sponsor, SponsorSlot } from '../../main/types';

const SponsorSlotsTableHeader = (): React.ReactElement => (
  <tr>
    <th>#</th>
    <th>Logo</th>
    <th>Name</th>
    <th>Donation</th>
    <th>Number of seats</th>
    <th>Payment received?</th>
    <th>Show logo on website?</th>
    <th>Actions</th>
  </tr>
);

const SponsorSlotsHeader = (props: {
  title: string;
  assignDisabled: boolean;
  onAssignClick: () => void;
}): React.ReactElement => (
  <div>
    <div className={'row'}>
      <div className={'col text-left'}>
        <h5 className="mb-0">{props.title}</h5>
      </div>
      <div className={'col text-right'}>
        <Button
          disabled={props.assignDisabled}
          className="p-1 text-sub"
          variant={'info'}
          onClick={props.onAssignClick}
        >
          Assign
        </Button>
      </div>
    </div>
  </div>
);

export const SponsorSlotsList = (): React.ReactElement => {
  const conferenceContext = useConference();
  const [conference, setConference] = useState<Conference | undefined>(
    conferenceContext.current,
  );

  const sponsorsContext = useSponsors();
  const [sponsorsList, setSponsorsList] = useState<Sponsor[]>([]);
  const [slots, setSlots] = useState<SponsorSlot[]>([]);
  const [assign, setAssign] = useState<SponsorSlot | null>(null);

  const unassignedSponsors: Sponsor[] = sponsorsList
    .filter(
      (sponsor) =>
        !slots.some(
          (slot) => slot.name === sponsor.name && sponsor.name !== assign?.name,
        ),
    )
    .sort((s1, s2) => s1.name.localeCompare(s2.name));

  useEffect(() => {
    if (conference === undefined) {
      conferenceContext.choices().then((c) => setConference(c[0]));
    } else {
      Promise.all([
        sponsorsContext.getSponsors(),
        sponsorsContext.getSponsorSlots(conference.id),
      ]).then(([spons, sls]) => {
        setSponsorsList(spons);
        setSlots(sls);
        if (conferenceContext.current) {
          setConference(conferenceContext.current);
        }
      });
    }
  }, [sponsorsContext, conferenceContext, conference]);

  const onAssignClick: () => void = async () => {
    setAssign({ id: uuid(), conference: conference?.id } as SponsorSlot);
  };

  const onEditClick: (index?: number) => void = (index?: number) => {
    if (index !== undefined) {
      setAssign(slots[index]);
    }
  };

  const onChangeSponsor = (sponsor: Sponsor): void => {
    setAssign({
      ...assign,
      name: sponsor.name,
      logo: sponsor.logo,
      url: sponsor.url,
    } as SponsorSlot);
  };

  const onChangeDonation = (event: ChangeEvent<HTMLInputElement>): void => {
    const donation = event.target.value;
    setAssign({ ...assign, donation } as SponsorSlot);
  };

  const onChangeNumberOfSeats = (numberOfSeats: number): void => {
    setAssign({ ...assign, numberOfSeats } as SponsorSlot);
  };

  const onChangePaymentReceived = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    const paymentReceived = Boolean(event.target.checked);
    setAssign({ ...assign, paymentReceived } as SponsorSlot);
  };

  const onChangeShowLogoReceived = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    const showLogo = Boolean(event.target.checked);
    setAssign({ ...assign, showLogo } as SponsorSlot);
  };

  const onDelete = async (index?: number): Promise<void> => {
    if (index !== undefined && conference !== undefined) {
      await sponsorsContext.deleteSponsorSlot(conference.id, index);
      setSlots(await sponsorsContext.getSponsorSlots(conference.id));
    }
  };

  const onSave = async (): Promise<void> => {
    if (assign != null && conference !== undefined) {
      if (assign.index === undefined) {
        await sponsorsContext.assignSponsorSlot(assign);
      } else {
        await sponsorsContext.updateSponsorSlot(assign);
      }
      setSlots(await sponsorsContext.getSponsorSlots(conference.id));
      setAssign(null);
    }
  };

  const onClose = (): void => {
    setAssign(null);
  };

  const sponsorsMapper: RowMapper = (row): React.ReactElement => {
    return (
      <SponsorSlotsListRow
        key={row.index}
        sponsor={row as SponsorSlot}
        onEditClick={() => onEditClick(row.index)}
        onDeleteClick={async () => await onDelete(row.index)}
      />
    );
  };

  return (
    <div>
      <PagingTable
        rows={slots}
        className={'sponsorslots'}
        listHeader={
          <SponsorSlotsHeader
            title="Sponsors"
            assignDisabled={unassignedSponsors.length === 0}
            onAssignClick={onAssignClick}
          />
        }
        tableHeader={<SponsorSlotsTableHeader />}
        emptyRow={<EmptySponsorSlotsListRow />}
        rowMapper={sponsorsMapper}
      />
      {assign != null && (
        <SponsorAssignModal
          assign={assign}
          sponsors={unassignedSponsors}
          onChangeSponsor={onChangeSponsor}
          onChangeDonation={onChangeDonation}
          onChangeNumberOfSeats={onChangeNumberOfSeats}
          onChangePaymentReceived={onChangePaymentReceived}
          onChangeShowLogoReceived={onChangeShowLogoReceived}
          onClose={onClose}
          onSave={onSave}
        />
      )}
    </div>
  );
};
