import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import './OperationsSubnav.scss';

export const OperationsSubnav = (): React.ReactElement => {
  const location = useLocation<any>();
  const { url } = useRouteMatch();

  return (
    <Nav
      activeKey={location.pathname}
      className="row subnav shadow-sm justify-content-end p-1 pr-4"
    >
      <Nav.Item>
        <Nav.Link
          eventKey="/operations/dashboard"
          as={Link}
          to={`${url}/dashboard`}
          className="text-info"
        >
          Dashboard
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          eventKey="/operations/processes"
          as={Link}
          to={`${url}/processes`}
          className="text-info"
        >
          Processes
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};
