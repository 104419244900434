import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { config } from '../../config';
import { Template } from '../types';

const protectedApi: string = config.apiEndpoints.templates;
const getUri = `${protectedApi}/templates`;
const putUri = `${protectedApi}/templates`;
const deleteUri = `${protectedApi}/templates`;

export interface TemplatesApi {
  createTemplate: () => Promise<Template>;
  deleteTemplate: (index: number) => Promise<void>;
  updateTemplate: (template: Template) => Promise<void>;
  getTemplates: () => Promise<Template[]>;
}

const findAllTemplates = async (): Promise<any> => {
  return (await axios.get(getUri)).data;
};

const putTemplate = async (template: Template): Promise<void> => {
  await axios.put(putUri, template);
};

const deleteTemplateByTitle = async (title: string): Promise<void> => {
  await axios.delete(`${deleteUri}/${title}`);
};

export class Templates implements TemplatesApi {
  private _templates: Template[];

  public constructor() {
    this._templates = [];
  }

  public createTemplate = async (): Promise<Template> => {
    const created: Template = {
      title: 'Template-' + uuid(),
      text: 'Some text',
      subject: 'Set a subject',
      htmlText: '<p>Some text</p>',
    };
    await putTemplate(created);
    created.index = this._templates.length;
    this._templates = [...this._templates, created];
    return created;
  };

  public deleteTemplate = async (index: number): Promise<void> => {
    if (index !== undefined) {
      const deleted = this._templates[index];
      const updated = [...this._templates.filter((_, i) => i !== index)];
      await deleteTemplateByTitle(deleted.title);
      this._templates = updated;
    }
  };

  public updateTemplate = async (template: Template): Promise<void> => {
    const index: number | undefined = template.index;
    if (index !== undefined) {
      const toPut: Template = { ...template };
      toPut.index = undefined;
      delete toPut.index;
      await putTemplate(toPut);
    }
    const updated = [...this._templates];
    updated[template.index ?? updated.length] = template;
    this._templates = updated;
  };

  public getTemplates = async (): Promise<Template[]> => {
    this._templates = (await findAllTemplates()).map(
      (t: Template, i: number) => ({ ...t, index: i }),
    );
    return [...this._templates];
  };
}
