import { LoggableEvent, Process } from '../main/types';
import { eventsToProcesses } from './operations-aggregation';
import { ChartDataset } from 'chart.js';
import { prettifyCommand } from '../common/transform';

const ATTENDEE_COMMANDS = [
  'ConfirmParticipationCommand',
  'CancelParticipationCommand',
  'ChangeParticipationDetailsCommand',
];
const APPLICANT_COMMANDS = [
  'ApplyForLotteryCommand',
  'WithdrawApplicationCommand',
  'SubscribeNewsletterCommand',
  'ConfirmNewsletterSubscriptionCommand',
  'CancelNewsletterSubscriptionCommand',
];

export function deriveAttendeeLabels(
  events: LoggableEvent[],
): string[] | undefined {
  const labelSet: Set<string> = events
    ?.filter((e) => e.type && ATTENDEE_COMMANDS.includes(e.type))
    .map((e) => prettifyCommand(e.type!))
    .reduce((labels, e) => {
      labels.add(e);
      return labels;
    }, new Set<string>());
  return Array.from(labelSet.values()).sort((one, two) =>
    one > two ? 1 : one < two ? -1 : 0,
  );
}

export function deriveAttendeeProcesses(events: LoggableEvent[]): Process[] {
  return eventsToProcesses(events).filter((p) =>
    ATTENDEE_COMMANDS.includes(p.command),
  );
}

export function deriveAttendeeDataset(
  attendeeProcesses: Process[],
): ChartDataset<'doughnut', number[]>[] {
  return deriveDataset(attendeeProcesses);
}

export function deriveApplicantLabels(
  events: LoggableEvent[],
): string[] | undefined {
  const labelSet: Set<string> = events
    ?.filter((e) => e.type && APPLICANT_COMMANDS.includes(e.type))
    .map((e) => prettifyCommand(e.type!))
    .reduce((labels, e) => {
      labels.add(e);
      return labels;
    }, new Set<string>());
  return Array.from(labelSet.values()).sort((one, two) =>
    one > two ? 1 : one < two ? -1 : 0,
  );
}

export function deriveApplicantProcesses(events: LoggableEvent[]): Process[] {
  return eventsToProcesses(events).filter((p) =>
    APPLICANT_COMMANDS.includes(p.command),
  );
}

export function deriveApplicantDataset(
  applicantProcesses: Process[],
): ChartDataset<'doughnut', number[]>[] {
  return deriveDataset(applicantProcesses);
}

export function deriveOrganizerProcesses(events: LoggableEvent[]): Process[] {
  return eventsToProcesses(events).filter(
    (p) =>
      p.command.endsWith('Command') &&
      !ATTENDEE_COMMANDS.concat(APPLICANT_COMMANDS).includes(p.command),
  );
}

function deriveDataset(
  processes: Process[],
): ChartDataset<'doughnut', number[]>[] {
  const counts: number[] = Array.from(
    processes
      .reduce((dataset, p) => {
        dataset.set(p.command, (dataset.get(p.command) ?? 0) + 1);
        return dataset;
      }, new Map<string, number>())
      .entries(),
  )
    .sort((one, two) => (one[0] > two[0] ? 1 : one[0] < two[0] ? -1 : 0))
    .map((n) => n[1]);
  const backgroundColor = ['#000', '#009932', '#ff7f20', '#008b8b', '#639'];
  return [{ data: counts, backgroundColor }];
}

export function deriveTable(processes: Process[]): [string, number[]][] {
  return Array.from(
    processes
      .reduce((dataset, p) => {
        const record: number[] = dataset.get(p.command) ?? [0, 0, 0, 0];
        const total: number = record[0] + 1;
        let failed: number = record[1];
        let error: number = record[2];
        let success: number = record[3];
        if (p.lastEvent.endsWith('FailedEvent')) {
          failed = failed + 1;
        } else if (p.lastEvent.endsWith('ErrorEvent')) {
          error = error + 1;
        } else {
          success = success + 1;
        }
        dataset.set(p.command, [total, failed, error, success]);
        return dataset;
      }, new Map<string, number[]>())
      .entries(),
  ).sort((one, two) => (one[0] > two[0] ? 1 : one[0] < two[0] ? -1 : 0));
}
