import React, { useEffect, useState } from 'react';
import './NewsletterSendForm.scss';
import { Button, ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { useNewsletter } from '../../main/NewsletterProvider';
import { v4 as uuid } from 'uuid';
import { SelectCallback, Template } from '../../main/types';

export const NewsletterSendForm = (): React.ReactElement => {
  const newsletter = useNewsletter();
  const [templates, setTemplates] = useState<Template[]>([]);
  const [selected, setSelected] = useState(-1);
  useEffect(() => {
    newsletter.getTemplates().then((t) => setTemplates(t));
    setSelected(newsletter.selectedTemplate);
  }, [newsletter]);

  const handleSelect: SelectCallback = async (
    result: string | null,
  ): Promise<void> => {
    if (result !== null) {
      await newsletter.selectTemplate(parseInt(result));
    } else {
      await newsletter.selectTemplate(-1);
    }
    setSelected(newsletter.selectedTemplate);
  };

  const handleSend = async (): Promise<void> => {
    await newsletter.send(templates[selected].title);
    await newsletter.selectTemplate(-1);
    setSelected(newsletter.selectedTemplate);
  };
  const substitute = (text: string | undefined): string | undefined => {
    return text
      ?.replace('{{name}}', 'Jane Doe')
      .replace('{{email}}', 'jane@doe.com')
      .replace('{{consentKey}}', uuid());
  };
  const selectedTemplateAvailable: boolean =
    templates.length >= selected &&
    selected > -1 &&
    templates[selected] !== undefined;
  return (
    <div className="row">
      <div className="send card mt-4">
        <div className="card-header">
          <h5 className="mb-0">Send newsletter to all subscribers</h5>
        </div>
        <div className="card-body">
          <span className="align-middle">Use template: </span>
          <DropdownButton
            as={ButtonGroup}
            variant="info"
            onSelect={handleSelect}
            title={
              selectedTemplateAvailable
                ? templates[selected].title
                : 'Select one'
            }
          >
            {templates.map((template) => (
              <Dropdown.Item
                key={template.index}
                eventKey={'' + template.index}
                active={template.index === selected}
              >
                <Dropdown.ItemText className="text-sub">
                  {template.title}
                </Dropdown.ItemText>
              </Dropdown.Item>
            ))}
          </DropdownButton>
          {selectedTemplateAvailable && (
            <div>
              <hr className="mt-4" />
              <h6 className="text-info mb-3">
                Preview: {templates[selected].title}
              </h6>
              <h3>{substitute(templates[selected].subject)}</h3>
              <div className="row">
                <div className="col card m-3 p-0">
                  <div className="card-header pb-1">
                    <h6>HTML (rendered):</h6>
                  </div>
                  <div
                    className="card-body"
                    dangerouslySetInnerHTML={{
                      __html: substitute(templates[selected].htmlText) ?? '',
                    }}
                  />
                </div>
                <div className="col card m-3 p-0">
                  <div className="card-header pb-1">
                    <h6>Plain text:</h6>
                  </div>
                  <div className="card-body">
                    <p>
                      <pre>{substitute(templates[selected].text)}</pre>
                    </p>
                  </div>
                </div>
              </div>
              <hr className="mb-4" />
              <div className="row">
                <div className="col text-right">
                  <Button
                    variant="outline-secondary mr-1"
                    onClick={(e) => handleSelect('-1', e)}
                  >
                    Clear
                  </Button>
                  <Button variant="info ml-1" onClick={handleSend}>
                    Send
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="card-footer"></div>
      </div>
    </div>
  );
};
