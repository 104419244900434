import { MessageType } from './messages';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

type MessageProps = {
  messageType: MessageType;
  messageHtml: string;
  dialogAction: Function;
};

export function ModalMessage(props: MessageProps): React.ReactElement {
  let { messageType, messageHtml, dialogAction } = props;
  const msgClass =
    messageType === MessageType.RESEND_SUCCESS ||
    messageType === MessageType.LOCK_SUCCESS
      ? 'alert-success'
      : messageType === MessageType.RESEND_FAILED
        ? 'alert-warning'
        : 'alert-danger';
  return (
    <Modal
      className={'p-0 m-0'}
      show={messageType !== undefined}
      onHide={() => dialogAction}
    >
      <div className={'m-0 alert ' + msgClass} role="alert">
        <div>
          <h3>
            {messageType === MessageType.RESEND_SUCCESS
              ? 'Mail was sent!'
              : messageType === MessageType.LOCK_SUCCESS
                ? 'Lock successful!'
                : messageType === MessageType.RESEND_FAILED
                  ? 'Resending failed!'
                  : 'Something unforeseen went wrong.'}
          </h3>
          <p
            className={'mt-4'}
            dangerouslySetInnerHTML={{
              __html: messageHtml.replaceAll('\n', '<br />'),
            }}
          ></p>
        </div>
        <hr />
        <div className={'text-end'}>
          <Button
            type={'submit'}
            onClick={() => {
              dialogAction();
            }}
          >
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );
}
