import React, { createContext, PropsWithChildren, useContext } from 'react';
import { Sponsor, SponsorSlot } from './types';
import { SponsorsApi } from './api/sponsors';

type SponsorsContext = {
  getSponsors: () => Promise<Sponsor[]>;
  createSponsor: () => Promise<Sponsor>;
  updateSponsor: (sponsor: Sponsor) => Promise<void>;
  deleteSponsor: (index: number) => Promise<void>;
  getSponsorSlots: (conference: string) => Promise<SponsorSlot[]>;
  assignSponsorSlot: (sponsor: SponsorSlot) => Promise<void>;
  updateSponsorSlot: (sponsor: SponsorSlot) => Promise<void>;
  deleteSponsorSlot: (conference: string, index?: number) => Promise<void>;
  upload: (file: File) => Promise<void>;
};

const DEFAULT_CONTEXT: SponsorsContext = {
  getSponsors: () => {
    console.log('not getting sponsors');
    return Promise.resolve([]);
  },
  createSponsor: async () => {
    console.log('not creating');
    return {} as Sponsor;
  },
  updateSponsor: async (sponsor) => {
    console.log('not updating', sponsor);
  },
  deleteSponsor: async (index?: number) => {
    console.log('not deleting', index);
  },
  getSponsorSlots: () => {
    console.log('not getting slots');
    return Promise.resolve([]);
  },
  assignSponsorSlot: async (slot: SponsorSlot) => {
    console.log('not assigning', slot);
  },
  updateSponsorSlot: async (slot: SponsorSlot) => {
    console.log('not updating', slot);
  },
  deleteSponsorSlot: async (conference, index) => {
    console.log('not deleting', conference, index);
  },
  upload: async (file: File) => {
    console.log('not uploading', file);
  },
};
const context = createContext(DEFAULT_CONTEXT);
export const useSponsors = (): SponsorsContext => useContext(context);

type ProvideSponsorsProps = {
  sponsorsApi: SponsorsApi;
};

export const SponsorsProvider = ({
  sponsorsApi,
  children,
}: PropsWithChildren<ProvideSponsorsProps>): React.ReactElement => {
  const sponsors = {
    createSponsor: sponsorsApi.createSponsor,
    deleteSponsor: sponsorsApi.deleteSponsor,
    updateSponsor: sponsorsApi.updateSponsor,
    getSponsors: sponsorsApi.getSponsors,
    getSponsorSlots: sponsorsApi.getSponsorSlots,
    assignSponsorSlot: sponsorsApi.assignSponsorSlot,
    updateSponsorSlot: sponsorsApi.updateSponsorSlot,
    deleteSponsorSlot: sponsorsApi.deleteSponsorSlot,
    upload: sponsorsApi.upload,
  };
  return <context.Provider value={sponsors}>{children}</context.Provider>;
};
