import React, { ChangeEvent, useEffect, useState } from 'react';
import './EventsDetails.scss';
import { useConference } from '../../main/ConferenceProvider';
import { InputField } from '../../form/InputField';
import RoomTypeRow from './RoomTypeRow';
import { Button } from 'react-bootstrap';
import FlatFeeRow from './FlatFeeRow';
import { useSponsors } from '../../main/SponsorsProvider';
import { DateField } from '../../form/DateField';
import {
  Conference,
  ConferenceState,
  FlatFee,
  RoomType,
} from '../../main/types';
import { ensure } from '../../common/ensure';

const emptyFlatFee: () => FlatFee = () => ({
  type: 'new',
  description: 'New flat fee',
  price: '0,00€',
});

const stateToString = (conf: Conference | undefined): string => {
  if (conf === undefined) return '';
  let state: string = String(conf.state).toLowerCase().replace('_', ' ');
  let index = 0;
  let nextIndex = state.indexOf(' ', index);
  while (nextIndex !== -1) {
    state =
      state.substring(0, nextIndex + 1) +
      state.charAt(nextIndex + 1).toUpperCase() +
      state.substring(nextIndex + 2);
    nextIndex = state.indexOf(' ', nextIndex + 2);
  }
  return state.charAt(0).toUpperCase() + state.substring(1);
};

export const EventsDetails = (): React.ReactElement => {
  const conference = useConference();
  const sponsors = useSponsors();
  const [sponsorsExist, setSponsorsExist] = useState(false);
  const [conf, setConf] = useState<Conference | undefined>(
    conference.current
      ? {
          ...conference.current,
          roomTypes: conference.current.roomTypes
            ? [...conference.current.roomTypes]
            : undefined,
        }
      : undefined,
  );

  useEffect(() => {
    conference.choices().then((c) => {
      const selected: Conference = conference.current
        ? conference.current
        : c[0];
      setConf(selected);
      sponsors
        .getSponsorSlots(selected.id)
        .then((s) => setSponsorsExist(s.length > 0));
    });
  }, [conference, sponsors]);

  const findCurrentConference = async (): Promise<Conference | undefined> => {
    const conferences = await conference.choices();
    return conferences.find((c) => c.id === conf?.id);
  };

  const setConferenceState = async (state: ConferenceState): Promise<void> => {
    if (conf) {
      const updated: Conference = { ...conf, state };
      setConf(updated);
      await conference.updateConference(updated);
    }
  };

  const onOpenRegistration = async (): Promise<void> =>
    await setConferenceState(ConferenceState.REGISTRATION);
  const onStartConference = async (): Promise<void> =>
    await setConferenceState(ConferenceState.IN_PROGRESS);
  const onEndConference = async (): Promise<void> =>
    await setConferenceState(ConferenceState.CLEANUP);
  const onFinalize = async (): Promise<void> =>
    await setConferenceState(ConferenceState.CONCLUDED);

  const onChangeNumber = (
    field: string,
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    event.persist();
    const updated: Conference = { ...ensure(conf) };
    updated[field] = parseInt(event.target.value);
    setConf(updated);
  };

  const onChangeString = (
    field: string,
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    event.persist();
    const updated: Conference = { ...ensure(conf) };
    updated[field] = event.target.value;
    setConf(updated);
  };

  const onChangeTitle = (event: ChangeEvent<HTMLInputElement>): void =>
    onChangeString('title', event);
  const onChangeYear = (event: ChangeEvent<HTMLInputElement>): void =>
    onChangeNumber('year', event);
  const onChangeByline = (event: ChangeEvent<HTMLInputElement>): void =>
    onChangeString('byline', event);
  const onChangeLocation = (event: ChangeEvent<HTMLInputElement>): void =>
    onChangeString('location', event);
  const onChangeStartDate = (event: ChangeEvent<HTMLInputElement>): void =>
    onChangeString('startDate', event);
  const onChangeEndDate = (event: ChangeEvent<HTMLInputElement>): void =>
    onChangeString('endDate', event);
  const onChangeDiversityRatio = (event: ChangeEvent<HTMLInputElement>): void =>
    onChangeNumber('diversityRatio', event);
  const onChangeMaxSeatsPerSponsor = (
    event: ChangeEvent<HTMLInputElement>,
  ): void => onChangeNumber('maxSeatsPerSponsor', event);

  const onChangeRoomTypeString = (
    field: string,
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    event.persist();
    if (conf) {
      const roomTypes: RoomType[] = conf.roomTypes ? [...conf.roomTypes] : [];
      const changed: RoomType = roomTypes[index];
      if (changed) {
        changed[field] = event.target.value;
        setConf({ ...conf, roomTypes });
      }
    }
  };

  const onChangeRoomTypeNumber = (
    field: string,
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    event.persist();
    if (conf) {
      const roomTypes: RoomType[] = conf.roomTypes ? [...conf.roomTypes] : [];
      const changed: RoomType = roomTypes[index];
      if (changed) {
        changed[field] = parseInt(event.target.value);
        setConf({ ...conf, roomTypes });
      }
    }
  };

  const onChangeRoomTypeType =
    (index: number) =>
    (event: ChangeEvent<HTMLInputElement>): void =>
      onChangeRoomTypeString('type', index, event);
  const onChangeRoomTypeDescription =
    (index: number) =>
    (event: ChangeEvent<HTMLInputElement>): void =>
      onChangeRoomTypeString('description', index, event);
  const onChangeRoomTypePrice =
    (index: number) =>
    (event: ChangeEvent<HTMLInputElement>): void =>
      onChangeRoomTypeString('pricePerNight', index, event);
  const onChangeRoomBeds =
    (index: number) =>
    (event: ChangeEvent<HTMLInputElement>): void =>
      onChangeRoomTypeNumber('beds', index, event);
  const onChangeRoomCount =
    (index: number) =>
    (event: ChangeEvent<HTMLInputElement>): void =>
      onChangeRoomTypeNumber('count', index, event);

  const onAddRoomType = (): void => {
    if (conf) {
      const types: RoomType[] = conf.roomTypes ? [...conf.roomTypes] : [];
      types.push({
        type: 'new',
        description: 'New room type',
        pricePerNight: '0,00€',
        beds: 1,
        count: 1,
      });
      const updated: Conference = { ...conf, roomTypes: types };
      setConf(updated);
    }
  };

  const onDeleteRoomType = (index: number) => (): void => {
    if (conf) {
      const types: RoomType[] = conf.roomTypes ? [...conf.roomTypes] : [];
      types.splice(index, 1);
      const updated: Conference = { ...conf, roomTypes: types };
      setConf(updated);
    }
  };

  const onFlatFeeChange = (field: string, index: number, value: any): void => {
    if (conf) {
      const flatFees: FlatFee[] = conf.flatFees ? [...conf.flatFees] : [];
      const changed: FlatFee = flatFees[index];
      if (changed) {
        changed[field] = value;
        setConf({ ...conf, flatFees });
      }
    }
  };

  const onChangeFlatFeeType =
    (index: number) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      event.persist();
      onFlatFeeChange('type', index, event.target.value);
    };
  const onChangeFlatFeeDescription =
    (index: number) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      event.persist();
      onFlatFeeChange('description', index, event.target.value);
    };
  const onChangeFlatFeePrice =
    (index: number) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      event.persist();
      onFlatFeeChange('price', index, event.target.value);
    };
  const onChangeFlatFeeSponsoring =
    (index: number) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      event.persist();
      onFlatFeeChange('excludeFromSponsoring', index, event.target.checked);
    };

  const onAddFlatFee = (): void => {
    if (conf) {
      const flatFees: FlatFee[] = conf.flatFees
        ? [...conf.flatFees, emptyFlatFee()]
        : [emptyFlatFee()];
      setConf({ ...conf, flatFees });
    }
  };

  const onDeleteFlatFee = (index: number) => (): void => {
    if (conf) {
      const flatFees: FlatFee[] = conf.flatFees ? [...conf.flatFees] : [];
      flatFees.splice(index, 1);
      setConf({ ...conf, flatFees });
    }
  };

  const onSave = async (): Promise<void> => {
    if (conf) await conference.updateConference(conf);
  };

  const onCancel = async (): Promise<void> => {
    const current = await findCurrentConference();
    if (current) {
      setConf(current);
    }
  };

  const onDelete = async (): Promise<void> => {
    const current = await findCurrentConference();
    if (current) {
      await conference.deleteConference(current);
      const conferences = await conference.choices();
      setConf(conferences[0]);
    }
  };

  const state = stateToString(conf);
  return (
    <div className={'row '}>
      {conf && (
        <div className={'venue card mt-4'}>
          <div className="card-header">
            <div className={'row'}>
              <div className={'col-md-6'}>
                <h5 className={'mt-2'}>
                  {conf.title + ' ' + conf.year + ': ' + state}
                </h5>
              </div>
              <div className={'col-md-6 action'}>
                {conf.state === ConferenceState.PREPARATION &&
                  !sponsorsExist && (
                    <Button variant="danger ml-1" onClick={onDelete}>
                      Delete Conference
                    </Button>
                  )}
                {conf.state === ConferenceState.PREPARATION && (
                  <Button variant="danger ml-1" onClick={onOpenRegistration}>
                    Open Registration
                  </Button>
                )}
                {conf.state === ConferenceState.REGISTRATION && (
                  <Button variant="danger ml-1" onClick={onStartConference}>
                    Start Conference
                  </Button>
                )}
                {conf.state === ConferenceState.IN_PROGRESS && (
                  <Button variant="danger ml-1" onClick={onEndConference}>
                    End Conference
                  </Button>
                )}
                {conf.state === ConferenceState.CLEANUP && (
                  <Button variant="danger ml-1" onClick={onFinalize}>
                    Finalize
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className={'row'}>
              <div className={'col-md-9'}>
                <InputField
                  className="mb-3"
                  disabled={conf.state !== ConferenceState.PREPARATION}
                  label={'Event title:'}
                  placeholder={'Title'}
                  value={conf.title}
                  onChange={onChangeTitle}
                />
              </div>
              <div className={'col-md-3'}>
                <InputField
                  className="mb-3"
                  disabled={conf.state !== ConferenceState.PREPARATION}
                  label="Year:"
                  placeholder="Year"
                  value={'' + conf.year}
                  onChange={onChangeYear}
                />
              </div>
              <div className={'col-md-12'}>
                <InputField
                  className="mb-3"
                  disabled={conf.state !== ConferenceState.PREPARATION}
                  label="Byline:"
                  placeholder="Byline"
                  value={conf.byline ?? ''}
                  onChange={onChangeByline}
                />
              </div>
            </div>
            <hr />
            <div className="row">
              <div className={'col-md-6'}>
                <div className="row">
                  <div className={'col-md-12'}>
                    <InputField
                      className="mb-3"
                      disabled={conf.state !== ConferenceState.PREPARATION}
                      label="Location:"
                      placeholder="Location"
                      value={conf.location}
                      onChange={onChangeLocation}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={'col-md-6'}>
                    <DateField
                      className="date mb-3"
                      disabled={conf.state !== ConferenceState.PREPARATION}
                      label="Start:"
                      value={conf.startDate ?? ''}
                      onChange={onChangeStartDate}
                    />
                  </div>
                  <div className={'col-md-6'}>
                    <DateField
                      className="date mb-3"
                      disabled={conf.state !== ConferenceState.PREPARATION}
                      label="End:"
                      value={conf.endDate ?? ''}
                      onChange={onChangeEndDate}
                    />
                  </div>
                </div>
              </div>
              <div className="vr" />
              <div className={'col-md-6'}>
                <InputField
                  className="mb-3"
                  disabled={conf.state !== ConferenceState.PREPARATION}
                  label={'Diversity ratio (%):'}
                  placeholder={'40'}
                  value={'' + (conf.diversityRatio ?? 0)}
                  onChange={onChangeDiversityRatio}
                />

                <InputField
                  className="mb-3"
                  disabled={conf.state !== ConferenceState.PREPARATION}
                  label={'Max seats/sponsor:'}
                  placeholder={'5'}
                  value={
                    conf.maxSeatsPerSponsor ? '' + conf.maxSeatsPerSponsor : ''
                  }
                  onChange={onChangeMaxSeatsPerSponsor}
                />
              </div>
            </div>
            <hr />
            <h6>Room Types:</h6>
            {conf.roomTypes?.map((r, index) => (
              <RoomTypeRow
                key={index}
                disabled={conf.state !== ConferenceState.PREPARATION}
                index={index}
                roomType={r}
                onChangeRoomBeds={onChangeRoomBeds(index)}
                onChangeRoomType={onChangeRoomTypeType(index)}
                onChangeRoomDescription={onChangeRoomTypeDescription(index)}
                onChangeRoomCount={onChangeRoomCount(index)}
                onChangeRoomPrice={onChangeRoomTypePrice(index)}
                onDeleteRoomType={onDeleteRoomType(index)}
              />
            ))}
            {conf.state === ConferenceState.PREPARATION && (
              <Button
                className="add-roomtype"
                variant="info"
                onClick={onAddRoomType}
              >
                +
              </Button>
            )}
            <hr />
            <h6>Flat Fee Items:</h6>
            {conf.flatFees?.map((f, index) => (
              <FlatFeeRow
                disabled={conf.state !== ConferenceState.PREPARATION}
                key={index}
                index={index}
                flatFee={f}
                onChangeFlatFee={onChangeFlatFeeType(index)}
                onChangeFlatFeePrice={onChangeFlatFeePrice(index)}
                onChangeFlatFeeSponsoring={onChangeFlatFeeSponsoring(index)}
                onChangeFlatFeeDescription={onChangeFlatFeeDescription(index)}
                onDeleteFlatFee={onDeleteFlatFee(index)}
              />
            ))}
            {conf.state === ConferenceState.PREPARATION && (
              <Button
                className="add-flatfee"
                variant="info"
                onClick={onAddFlatFee}
              >
                +
              </Button>
            )}
          </div>
          <div className="card-footer">
            {conf.state === ConferenceState.PREPARATION && (
              <Button variant="secondary mr-1" onClick={onCancel}>
                Cancel
              </Button>
            )}
            {conf.state === ConferenceState.PREPARATION && (
              <Button variant="info ml-1" onClick={onSave}>
                Save changes
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
