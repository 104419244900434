import React, { createContext, PropsWithChildren, useContext } from 'react';
import {
  Applicant,
  Booking,
  Identifier,
  LotteryInput,
  RoomTypeName,
  UUID,
} from './types';
import { ApplicantsApi } from './api/applicants';

type ApplicantsContext = {
  getApplicants: (conference: UUID) => Promise<Applicant[]>;
  updateApplicant: (applicant: Applicant) => Promise<void>;
  deleteApplicant: (conference: Identifier, id?: UUID) => Promise<void>;
  moveToAttendees: (
    index?: number,
    reason?: string,
    roomType?: RoomTypeName,
  ) => Promise<void>;
  runLottery: (input: LotteryInput) => Promise<Booking[] | undefined>;
  executeLottery: (bookings: Booking[], conferenceId: UUID) => Promise<void>;
  cancelLottery: () => Promise<void>;
};

const DEFAULT_CONTEXT: ApplicantsContext = {
  getApplicants: (_conference: UUID) => {
    console.log('not getting applicants');
    return Promise.resolve([]);
  },
  updateApplicant: async (applicant) => {
    console.log('not updating', applicant);
  },
  deleteApplicant: async (id?: UUID) => {
    console.log('not deleting', id);
  },
  moveToAttendees: async (index?: number) => {
    console.log('not moving to attendees', index);
  },
  runLottery: async (input: LotteryInput) => {
    console.log('not running lottery', input);
    return [];
  },
  executeLottery: async (bookings) => {
    console.log('not executing lottery', bookings);
  },
  cancelLottery: async () => {
    console.log('not canceling lottery');
  },
};
const context = createContext(DEFAULT_CONTEXT);
export const useApplicants = (): ApplicantsContext => useContext(context);

type ProvideApplicantsProps = {
  applicantsApi: ApplicantsApi;
};

export const ApplicantsProvider = ({
  applicantsApi,
  children,
}: PropsWithChildren<ProvideApplicantsProps>): React.ReactElement => {
  const applicants = {
    deleteApplicant: applicantsApi.deleteApplicant,
    updateApplicant: applicantsApi.updateApplicant,
    getApplicants: applicantsApi.getApplicants,
    moveToAttendees: applicantsApi.moveToAttendees,
    runLottery: applicantsApi.runLottery,
    executeLottery: applicantsApi.executeLottery,
    cancelLottery: applicantsApi.cancelLottery,
  };
  return <context.Provider value={applicants}>{children}</context.Provider>;
};
