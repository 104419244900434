import React, { useEffect, useState } from 'react';
import './NewsletterHistory.scss';
import { useNewsletter } from '../../main/NewsletterProvider';
import { PagingTable, RowMapper } from '../../table/PagingTable';
import { HistoryEntry } from '../../main/types';

const NewsletterHistoryListRow = ({
  entry,
}: {
  entry: HistoryEntry;
}): React.ReactElement => (
  <tr>
    <td>{entry.index}</td>
    <td>{entry.date}</td>
    <td>{entry.time}</td>
    <td>{entry.username}</td>
    <td>{entry.email}</td>
    <td>{entry.template}</td>
  </tr>
);

const EmptyNewsletterHistoryListRow = (): JSX.Element => (
  <tr>
    <td className="text-center" colSpan={6}>
      There are no history newsletters to display.
    </td>
  </tr>
);

export const NewsletterHistory = (): React.ReactElement => {
  const newsletter = useNewsletter();
  const [history, setHistory] = useState<HistoryEntry[]>([]);
  useEffect(() => {
    newsletter.getHistory().then((h) => setHistory(h));
  }, [newsletter]);

  const NewsletterHistoryHeader = (): React.ReactElement => (
    <h5>Newsletter history</h5>
  );
  const NewsletterHistoryTableHeader = (): React.ReactElement => (
    <tr>
      <th>#</th>
      <th>Date</th>
      <th>Time</th>
      <th>User</th>
      <th>Email</th>
      <th>Template</th>
    </tr>
  );
  const entryMapper: RowMapper = (row) => (
    <NewsletterHistoryListRow key={row.index} entry={row as HistoryEntry} />
  );

  return (
    <PagingTable
      rows={history}
      className={'templates'}
      listHeader={<NewsletterHistoryHeader />}
      tableHeader={<NewsletterHistoryTableHeader />}
      emptyRow={<EmptyNewsletterHistoryListRow />}
      rowMapper={entryMapper}
    />
  );
};
