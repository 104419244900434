import React from 'react';
import './NewsletterScreen.scss';
import { Navigation } from '../navigation/Navigation';
import { NewsletterSubnav } from './NewsletterSubnav';
import { Redirect, Route, Switch } from 'react-router-dom';
import { NewsletterSubscribersList } from './subscribers/NewsletterSubscribersList';
import { NewsletterTemplatesList } from './templates/NewsletterTemplatesList';
import { NewsletterSendForm } from './send/NewsletterSendForm';
import { NewsletterHistory } from './history/NewsletterHistory';

export const NewsletterScreen = (): React.ReactElement => {
  return (
    <div>
      <Navigation />
      <NewsletterSubnav />
      <Switch>
        <Route exact path="/newsletter">
          <Redirect to="/newsletter/send" />
        </Route>
        <Route path="/newsletter/send">
          <NewsletterSendForm />
        </Route>
        <Route path="/newsletter/subscribers">
          <NewsletterSubscribersList />
        </Route>
        <Route path="/newsletter/templates">
          <NewsletterTemplatesList />
        </Route>
        <Route path="/newsletter/history">
          <NewsletterHistory />
        </Route>
      </Switch>
      <footer></footer>
    </div>
  );
};
