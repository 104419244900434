/* eslint-disable no-self-assign */
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
import { signIn, signOut } from './api/login';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { config } from '../config';

type AuthContext = {
  user: string;
  signIn: (username: string, password: string) => Promise<any>;
  signOut: () => Promise<void>;
};
const DEFAULT_CONTEXT: AuthContext = {
  user: '',
  signIn: async (username: string, password: string) =>
    console.log('Not logging in: ', username, password),
  signOut: async () => console.log('not logging out'),
};
const authContext = createContext(DEFAULT_CONTEXT);
export const useAuth = (): AuthContext => useContext(authContext);

const useProvideAuth = (): AuthContext => {
  const [user, setUser] = useState('');
  Auth.currentAuthenticatedUser().then((loggedIn) => {
    setUser(loggedIn.username);
  });
  // workaround for FF bug: https://bugzilla.mozilla.org/show_bug.cgi?id=1422334
  // noinspection SillyAssignmentJS
  window.location.hash = window.location.hash;
  Auth.currentSession().then((sess) => {
    axios.defaults.headers.common['Authorization'] =
      'Bearer ' + sess.getIdToken().getJwtToken();
  });
  const signin = async (
    username: string,
    password: string,
  ): Promise<AuthContext> => {
    return signIn(username, password).then((info) => {
      setUser(info.username);
      return info;
    });
  };

  const signout = async (): Promise<void> => {
    return signOut()
      .then(() => {
        setUser('');
      })
      .then(() => {
        window.location.href = config.auth.oauth.redirectSignOut;
      });
  };

  return {
    user,
    signIn: signin,
    signOut: signout,
  };
};

export const AuthProvider = ({
  children,
}: PropsWithChildren<any>): React.ReactElement => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};
