import { Price } from '../main/types';

export const parsePrice = (price: Price): number => {
  const num: number = parseFloat(price.replace(',', '.'));
  if (isNaN(num)) {
    console.error(
      new Error(
        'Conversion error: ' +
          price +
          ' evaluated to NaN. Returning 0 instead.',
      ),
    );
    return 0;
  }
  return num;
};

export const formatEuro = (amount: number): Price => {
  let total: string = amount.toFixed(2);
  //if (total.endsWith('.00')) total = total.substring(0, total.length - 3)
  total = total.replace('.', ',');
  return total + '€';
};
