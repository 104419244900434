import { Stats } from './types';
import React, { createContext, PropsWithChildren, useContext } from 'react';
import { BookingApi } from './api/booking';

export type BookingContext = {
  getStats: (conference: string) => Promise<Stats>;
};

const NIL_STATS: Stats = {
  attendeeCount: 0,
  attendeesConfirmed: 0,
  sponsoringAmount: '0€',
  expensesAmount: '0€',
  financialAidAmount: '0€',
  financialAidRequestersCount: 0,
  bookedPackages: { conference: 0, training: 0, workshops: 0 },
  bookedBeds: { single: 0 },
  bookedBedsDiversity: { single: 0 },
};

const DEFAULT_CONTEXT: BookingContext = {
  getStats: (_: string) => {
    console.log('not getting');
    return Promise.resolve(NIL_STATS);
  },
};

const bookingContext = createContext(DEFAULT_CONTEXT);
export const useBooking = (): BookingContext => useContext(bookingContext);

export type BookingProviderProps = {
  bookingApi: BookingApi;
};

export function BookingProvider({
  bookingApi,
  children,
}: PropsWithChildren<BookingProviderProps>): React.ReactElement {
  const getStats = async (conference: string): Promise<Stats> => {
    return await bookingApi.getStats(conference);
  };

  const context = {
    getStats,
  };
  return (
    <bookingContext.Provider value={context}>
      {children}
    </bookingContext.Provider>
  );
}
