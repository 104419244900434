import React, {
  ChangeEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { useConference } from '../../main/ConferenceProvider';
import { Booking, Conference, RoomTypeName, Stats } from '../../main/types';
import './LotteryScreen.scss';
import { Button, Form } from 'react-bootstrap';
import { InputField } from '../../form/InputField';
import { useBooking } from '../../main/BookingProvider';
import { ensure } from '../../common/ensure';
import { useApplicants } from '../../main/ApplicantsProvider';
import { FormSubmitHandler } from '../../form/events';
import { HeadlessPagingTable } from '../../table/HeadlessPagingTable';
import { RowMapper } from '../../table/PagingTable';
import {
  EmptyLotteryBookingsListRow,
  LotteryBookingsListRow,
} from './LotteryBookingsListRow';
import { ModalMessage } from '../../modal/ModalMessage';
import { MessageType } from '../../modal/messages';
import { ConferenceBeds } from './bed-count';

const LotteryBookingsListTableHeader = (): React.ReactElement => (
  <tr>
    <th>#</th>
    <th>Email</th>
    <th>Room type</th>
    <th>Diversity</th>
    <th>Actions</th>
  </tr>
);

export function LotteryScreen(): React.ReactElement {
  const conferenceContext = useConference();
  const applicantContext = useApplicants();
  const shoppingContext = useBooking();

  const [conference, setConference] = useState<Conference | undefined>();
  const [stats, setStats] = useState<Stats>();
  const [beds, setBeds] = useState<ConferenceBeds | undefined>(undefined);
  const [bookings, setBookings] = useState<Booking[] | undefined>(undefined);
  const [messageType, setMessageType] = useState<MessageType | undefined>(
    undefined,
  );
  const [bedsContingent, setBedsContingent] = useState(
    {} as Record<RoomTypeName, number>,
  );
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    if (conference === undefined) {
      if (conferenceContext.current) setConference(conferenceContext.current);
      else
        conferenceContext
          .choices()
          .then((c) => setConference(c[0]))
          .catch((e): void => {
            console.error('Error while getting conference info: %s', e);
          });
    }
  }, [conferenceContext, conference]);

  useEffect(() => {
    if (conference !== undefined && stats === undefined) {
      shoppingContext
        .getStats(conference.id)
        .then((s) => {
          setStats(s);
        })
        .catch((e): void => {
          console.error('Error while getting conference stats: %s', e);
        });
    }
  }, [conference, stats, shoppingContext]);

  useEffect(() => {
    if (conference !== undefined && stats !== undefined && beds === undefined) {
      const confBeds = new ConferenceBeds(conference, stats);
      setBeds(confBeds);
      setBedsContingent(confBeds.getLotteryBedsContingent());
    }
  }, [conference, stats, beds]);

  const onChange =
    (roomType: RoomTypeName): ChangeEventHandler<HTMLInputElement> =>
    (event) => {
      event.preventDefault();
      const count =
        event.target.value !== '' ? parseInt(event.target.value) : 0;
      beds!.setLotteryBedsContingentForRoomType(roomType, count);
      setBedsContingent(beds!.getLotteryBedsContingent());
    };

  const onSubmit: FormSubmitHandler = async (event) => {
    event.preventDefault();
    if (conference) {
      await applicantContext
        .runLottery({
          conferenceId: conference.id,
          diversityPercentage: conference.diversityRatio ?? 0,
          bedsToAllocate: ensure(beds).getLotteryBedsContingent(),
        })
        .then((bookings) => setBookings(bookings));
    }
  };

  const onCancel: MouseEventHandler = async (event) => {
    event.preventDefault();
    await applicantContext.cancelLottery();
  };

  const onConfirm: MouseEventHandler = async (event) => {
    event.preventDefault();
    await applicantContext
      .executeLottery(ensure(bookings), ensure(conference?.id))
      .catch((e) => {
        console.error('Outer:', e);
      })
      .then(async (message) => {
        console.log('success', message);
      });
  };

  const bookingsMapper: RowMapper = (row) => {
    return (
      <LotteryBookingsListRow
        key={row.index}
        index={ensure(row.index, 'List rows must have an index.')}
        booking={row as Booking}
      />
    );
  };

  const onClose = async (): Promise<void> => {
    setMessageType(undefined);
    setMessage('');
  };

  return (
    <div
      className={`container lottery ${conference && stats && beds ? '' : 'd-none'}`}
    >
      <div className="row">
        <div className="col-12">
          <div className="card mt-4">
            <div className="row">
              <div className="col-12">
                <div className="card-header">
                  <h5 className={'mt-2'}>Lottery</h5>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card-body p-4">
                  {conference && stats && beds && (
                    <Form>
                      <div className={'row'}>
                        <h6 className={'ml-3 mb-4'}>Current stats</h6>
                      </div>
                      <div className={'row'}>
                        <div className={'col-6 pr-1'}>
                          <InputField
                            className={'mb-2'}
                            disabled
                            label={'Attendees total'}
                            value={'' + ensure(stats).attendeeCount}
                            placeholder={'0'}
                          />
                        </div>
                        <div className={'col-6 pl-1'}>
                          <InputField
                            className={'mb-2'}
                            disabled
                            label={'Available beds total'}
                            value={'' + ensure(beds).getAvailableBedsTotal()}
                            placeholder={'0'}
                          />
                        </div>

                        <div className={'col-6 pr-1'}>
                          <InputField
                            className={'mb-2'}
                            label={'Diversity Percentage'}
                            disabled
                            value={'' + (conference.diversityRatio ?? 0)}
                            placeholder={'40'}
                          />
                        </div>
                        <div className={'col-6 pl-1'}>
                          <InputField
                            disabled
                            label={'Assigned beds diversity'}
                            value={'' + ensure(beds).getAssignedBedsDiversity()}
                            placeholder={'0'}
                          />
                        </div>
                      </div>
                      <hr />
                      <div className={'row'}>
                        <h6 className={'ml-3 mb-4'}>
                          Assign # of beds to give away
                        </h6>
                      </div>
                      <div className={'row'}>
                        <div className={'col-3'}></div>
                        <div className={'col-6 pr-1'}>
                          {conference &&
                            beds &&
                            conference.roomTypes?.map((r) => (
                              <InputField
                                key={r.type}
                                className={'beds mb-2'}
                                label={r.description}
                                value={'' + bedsContingent[r.type]}
                                placeholder={'Assign beds'}
                                onChange={onChange(r.type)}
                              />
                            ))}
                        </div>
                        <div className={'col-3'}></div>
                      </div>
                      <div className={'row mt-4 pl-0 pr-0'}>
                        <div className={'col-9'}></div>
                        <div className={'col-3 text-right'}>
                          <Button
                            type={'submit'}
                            variant={'info'}
                            onClick={onSubmit}
                          >
                            Run lottery draw
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </div>
              </div>
            </div>
            {bookings && (
              <div>
                <hr />
                <div className="row">
                  <HeadlessPagingTable
                    className={'bookings'}
                    rows={bookings ?? []}
                    rowMapper={bookingsMapper}
                    emptyRow={<EmptyLotteryBookingsListRow />}
                    tableHeader={<LotteryBookingsListTableHeader />}
                  />
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card-footer p-4 text-right">
                      <Button
                        className="mr-1"
                        variant={'danger'}
                        onClick={onCancel}
                      >
                        Cancel this draw
                      </Button>
                      <Button
                        className="ml-1"
                        variant={'success'}
                        onClick={onConfirm}
                      >
                        Confirm this draw
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {messageType !== undefined && (
        <ModalMessage
          dialogAction={onClose}
          messageType={messageType}
          messageHtml={message}
        />
      )}
    </div>
  );
}
