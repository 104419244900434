import moment from 'moment';
import axios from 'axios';
import { config } from '../../config';
import { HistoryEntry } from '../types';

const TIMESTAMP_FORMAT: string = 'YYYY/MM/DD HH:mm:ss';
const DATE_FORMAT: string = 'ddd DD.MM.YYYY';
const TIME_FORMAT: string = 'HH:mm:ss';

const protectedApi = config.apiEndpoints.newsletters;
const getUri = `${protectedApi}/newsletters`;
const postUri = `${protectedApi}/newsletters`;

export interface NewsletterApi {
  send: (title: string) => Promise<void>;
  getHistory: () => Promise<HistoryEntry[]>;
}

async function postNewsletter(timestamp: string, title: string): Promise<void> {
  await axios.post(postUri, { timestamp, template: title });
}

interface StoredHistoryEntry {
  email?: string;
  username?: string;
  timestamp: string;
  template: string;
}

export const compareHistoryEntries: any = (
  a: HistoryEntry,
  b: HistoryEntry,
): number => {
  const dateTimeFormat: moment.MomentFormatSpecification =
    DATE_FORMAT + ',' + TIME_FORMAT;
  const firstDate: moment.Moment = moment(
    a.date + ',' + a.time,
    dateTimeFormat,
  );
  const secondDate: moment.MomentInput = moment(
    b.date + ',' + b.time,
    dateTimeFormat,
  );
  if (firstDate.isBefore(secondDate)) {
    return 1;
  } else if (firstDate.isAfter(secondDate)) {
    return -1;
  } else {
    return 0;
  }
};

async function getNewsletters(): Promise<StoredHistoryEntry[]> {
  return (await axios.get(getUri)).data;
}

export class Newsletter implements NewsletterApi {
  private _history: HistoryEntry[];

  constructor() {
    this._history = [];
  }

  public send = async (title: string): Promise<void> => {
    const timestamp = moment().format(TIMESTAMP_FORMAT);
    await postNewsletter(timestamp, title);
  };

  public getHistory = async (): Promise<HistoryEntry[]> => {
    this._history = (await getNewsletters())
      .map((stored, i) => ({
        index: i,
        date: moment(stored.timestamp, TIMESTAMP_FORMAT).format(DATE_FORMAT),
        time: moment(stored.timestamp, TIMESTAMP_FORMAT).format(TIME_FORMAT),
        template: stored.template,
        email: stored.email,
        username: stored.username,
      }))
      .sort(compareHistoryEntries);
    return [...this._history];
  };
}
