import React, { ChangeEvent, useEffect, useState } from 'react';
import './SponsorsList.scss';
import { PagingTable, RowMapper } from '../../table/PagingTable';
import { EmptySponsorsListRow, SponsorsListRow } from './SponsorsListRow';
import { useSponsors } from '../../main/SponsorsProvider';
import { SponsorEditModal } from './SponsorEditModal';
import { Button } from 'react-bootstrap';
import { Sponsor } from '../../main/types';

const SponsorsTableHeader = (): React.ReactElement => (
  <tr>
    <th>#</th>
    <th>Logo</th>
    <th>Name</th>
    <th>Contact Name</th>
    <th>Contact Email</th>
    <th>Contact Phone</th>
    <th>Actions</th>
  </tr>
);

const SponsorsHeader = (props: {
  title: string;
  onCreateClick: () => void;
}): React.ReactElement => (
  <div>
    <div className={'row'}>
      <div className={'col text-left'}>
        <h5 className="mb-0">{props.title}</h5>
      </div>
      <div className={'col text-right'}>
        <Button
          className="p-1 text-sub"
          variant={'info'}
          onClick={props.onCreateClick}
        >
          Create
        </Button>
      </div>
    </div>
  </div>
);

export const SponsorsList = (): React.ReactElement => {
  const [edit, setEdit] = useState<Sponsor | null>(null);
  const sponsors = useSponsors();
  const [sponsorList, setSponsorList] = useState<Sponsor[]>([]);

  useEffect(() => {
    sponsors.getSponsors().then((s) => setSponsorList(s));
  }, [sponsors]);

  const onUploadLogo = async (
    event: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    event.persist();
    if (edit != null) {
      const file = event.target.files?.item(0);
      if (file) {
        await sponsors.upload(file);
        const updated: Sponsor = { ...edit, logo: file?.name ?? edit.logo };
        setEdit(updated);
      }
    }
  };

  const onChangeLogo = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    if (edit != null) {
      const updated: Sponsor = { ...edit, logo: event.target.value };
      setEdit(updated);
    }
  };

  const onChangeName = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    if (edit != null) {
      const updated: Sponsor = { ...edit, name: event.target.value };
      setEdit(updated);
    }
  };

  const onChangeUrl = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    if (edit != null) {
      const updated: Sponsor = { ...edit, url: event.target.value };
      setEdit(updated);
    }
  };

  const onChangeContactName = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    if (edit != null) {
      const updated: Sponsor = { ...edit, contactName: event.target.value };
      setEdit(updated);
    }
  };

  const onChangeContactEmail = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    if (edit != null) {
      const updated: Sponsor = { ...edit, contactEmail: event.target.value };
      setEdit(updated);
    }
  };

  const onChangeContactPhone = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    if (edit != null) {
      const updated: Sponsor = { ...edit, contactPhone: event.target.value };
      setEdit(updated);
    }
  };

  const onSave = async (): Promise<void> => {
    if (edit != null) {
      await sponsors.updateSponsor(edit);
      setSponsorList(await sponsors.getSponsors());
      setEdit(null);
    }
  };

  const onClose = (): void => {
    setEdit(null);
  };

  const onDeleteClick = async (index: number | undefined) => {
    if (index !== undefined) {
      await sponsors.deleteSponsor(index);
      setSponsorList(await sponsors.getSponsors());
    }
  };

  const sponsorsMapper: RowMapper = (row): React.ReactElement => (
    <SponsorsListRow
      key={row.index}
      sponsor={row as Sponsor}
      onEditClick={() => setEdit(row as Sponsor)}
      onDeleteClick={() => onDeleteClick(row.index)}
    />
  );
  const onCreateClick: () => void = async (): Promise<void> => {
    const created = await sponsors.createSponsor();
    setSponsorList(await sponsors.getSponsors());
    setEdit(created);
  };
  return (
    <div>
      <PagingTable
        rows={sponsorList}
        className={'sponsors'}
        listHeader={
          <SponsorsHeader title="Sponsors" onCreateClick={onCreateClick} />
        }
        tableHeader={<SponsorsTableHeader />}
        emptyRow={<EmptySponsorsListRow />}
        rowMapper={sponsorsMapper}
      />
      {edit != null && (
        <SponsorEditModal
          edit={edit}
          onUploadLogo={onUploadLogo}
          onChangeLogo={onChangeLogo}
          onChangeName={onChangeName}
          onChangeUrl={onChangeUrl}
          onChangeContactName={onChangeContactName}
          onChangeContactEmail={onChangeContactEmail}
          onChangeContactPhone={onChangeContactPhone}
          onClose={onClose}
          onSave={onSave}
        />
      )}
    </div>
  );
};
