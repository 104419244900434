import { Nav, NavDropdown } from 'react-bootstrap';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../main/AuthProvider';

export const Navigation = (): React.ReactElement => {
  const location: any = useLocation<any>();
  const auth = useAuth();
  const fullpath: string = location.pathname;
  const slashIndex: number = fullpath.indexOf('/', 1);
  const length: number = slashIndex > 0 ? slashIndex : fullpath.length;
  const mainpath: string = fullpath.substr(0, length);
  return (
    <header className="nav-header row bg-info shadow-sm">
      <div className="col-4">
        <img
          className="logo"
          alt="SoCraTes logo"
          src="https://raw.githubusercontent.com/softwerkskammer/softwerkskammer-logos/master/SoCraTes/2020/SoCraTesWappen_2020.png"
        />
        <h5>SoCraTes Administration</h5>
      </div>
      <div className="col-2"></div>
      <Nav
        activeKey={mainpath}
        variant="pills"
        className="col-6 justify-content-end"
      >
        <Nav.Item>
          <Nav.Link
            as={Link}
            eventKey="/ops"
            className="bg-info"
            to="/operations"
          >
            Operations
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            eventKey="/newsletter"
            className="bg-info"
            to="/newsletter"
          >
            Newsletter
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            eventKey="/sponsors"
            className="bg-info"
            to="/sponsors"
          >
            Sponsors
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            eventKey="/events"
            className="bg-info"
            to="/events"
          >
            Events
          </Nav.Link>
        </Nav.Item>
        <NavDropdown id="nav-dropdown" title={auth.user} className="text-dark">
          <NavDropdown.Item
            disabled
            className="text-dark bg-info"
            eventKey="myProfile"
          >
            My Profile
          </NavDropdown.Item>
          <NavDropdown.Divider className="bg-info" />
          <NavDropdown.Item
            className="text-dark bg-info"
            eventKey="signOut"
            onSelect={() => {
              auth.signOut();
            }}
          >
            Sign Out
          </NavDropdown.Item>
        </NavDropdown>
      </Nav>
    </header>
  );
};
