import { Nav } from 'react-bootstrap';
import React from 'react';
import './NewsletterSubnav.scss';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';

export const NewsletterSubnav = (): React.ReactElement => {
  const location = useLocation<any>();

  const { url } = useRouteMatch();
  return (
    <Nav
      activeKey={location.pathname}
      className="row subnav shadow-sm justify-content-end p-1 pr-4"
    >
      <Nav.Item>
        <Nav.Link
          eventKey="/newsletter/send"
          as={Link}
          to={`${url}/send`}
          className="text-info"
        >
          Send Newsletter
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          eventKey="/newsletter/subscribers"
          as={Link}
          to={`${url}/subscribers`}
          className="text-info"
        >
          Subscribers
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          eventKey="/newsletter/templates"
          as={Link}
          to={`${url}/templates`}
          className="text-info"
        >
          Templates
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          eventKey="/newsletter/history"
          as={Link}
          to={`${url}/history`}
          className="text-info pr-4"
        >
          History
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};
