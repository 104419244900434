import axios from 'axios';
import { config } from '../../config';
import { LoggableEvent, UUID } from '../types';

const eventsApi: string = config.apiEndpoints.events;
const eventsEndpoint: string = `${eventsApi}/events`;

export type EventsApi = {
  getEvents: () => Promise<LoggableEvent[]>;
  getEventsByCorrelationId: (correlationId: string) => Promise<LoggableEvent[]>;
};

const findAllEvents = async (): Promise<LoggableEvent[]> => {
  return (await axios.get(eventsEndpoint)).data;
};

const findAllEventsByCorrelationId = async (
  correlationId: UUID,
): Promise<LoggableEvent[]> => {
  return (await axios.get(eventsEndpoint + '/' + correlationId)).data;
};

export class Events implements EventsApi {
  getEvents = async (): Promise<LoggableEvent[]> => {
    return await findAllEvents();
  };
  getEventsByCorrelationId = async (
    correlationId: UUID,
  ): Promise<LoggableEvent[]> => {
    return await findAllEventsByCorrelationId(correlationId);
  };
}
