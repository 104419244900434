import axios from 'axios';
import { Subscriber } from '../types';
import { config } from '../../config';

const protectedApi: string = config.apiEndpoints.subscribers;
const getUri = `${protectedApi}/subscribers`;
const deleteUri = `${protectedApi}/subscribers`;

export type SubscribersApi = {
  getSubscribers: () => Promise<Subscriber[]>;
  deleteSubscriber: (index?: number) => Promise<void>;
};

export class Subscribers implements SubscribersApi {
  private _subscribers: Subscriber[];

  constructor() {
    this._subscribers = [];
  }

  public getSubscribers = async (): Promise<Subscriber[]> => {
    await this._findAllSubscribers();
    return [...this._subscribers];
  };

  public deleteSubscriber = async (index?: number): Promise<void> => {
    if (index !== undefined) {
      const deleted = this._subscribers[index];
      const updated = [...this._subscribers.filter((_, i) => i !== index)];
      const email: string = deleted.email;
      await this._deleteSubscriberByEmail(email);
      this._subscribers = updated;
    }
  };

  private _findAllSubscribers = async (): Promise<void> => {
    this._subscribers = (await axios.get(getUri)).data;
  };

  private _deleteSubscriberByEmail = async (email: string): Promise<void> => {
    await axios.delete(`${deleteUri}/${email}`);
  };
}
