import React, { createContext, PropsWithChildren, useContext } from 'react';
import { EventsApi } from './api/events';
import { LoggableEvent } from './types';

type EventsContext = {
  getEvents: () => Promise<LoggableEvent[]>;
  getEventsByCorrelationId: (correlationId: string) => Promise<LoggableEvent[]>;
};

const DEFAULT_CONTEXT: EventsContext = {
  getEvents: () => {
    console.log('not getting events');
    return Promise.resolve([] as LoggableEvent[]);
  },
  getEventsByCorrelationId: (correlationId: string) => {
    console.log('not getting events by correlation id: ' + correlationId);
    return Promise.resolve([] as LoggableEvent[]);
  },
};

const eventsContext = createContext(DEFAULT_CONTEXT);

export const useEvents = (): EventsContext => useContext(eventsContext);

type ProvideEventsProps = {
  eventsApi: EventsApi;
};

export const EventsProvider = ({
  eventsApi,
  children,
}: PropsWithChildren<ProvideEventsProps>): React.ReactElement => {
  const context = {
    getEvents: eventsApi.getEvents,
    getEventsByCorrelationId: eventsApi.getEventsByCorrelationId,
  };

  return (
    <eventsContext.Provider value={context}>{children}</eventsContext.Provider>
  );
};
