import { Nav, NavDropdown } from 'react-bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import './SponsorsSubnav.scss';
import { useConference } from '../main/ConferenceProvider';
import { Conference } from '../main/types';

const eventTitle = (conf: Conference | undefined): string => {
  return conf ? `${conf.title} ${conf.year}` : 'Not available';
};

export const SponsorsSubnav = (): React.ReactElement => {
  const location = useLocation<any>();
  const { url } = useRouteMatch();

  const conferenceContext = useConference();
  const getChoices = useCallback(async () => {
    return await conferenceContext.choices();
  }, [conferenceContext]);

  const [conference, setConference] = useState<Conference | undefined>(
    conferenceContext.current,
  );
  const [conferences, setConferences] = useState<Conference[]>([]);
  useEffect(() => {
    if (conferences.length === 0) {
      getChoices().then((c) => {
        setConferences(c);
        setConference(conferenceContext.current ?? c[0]);
      });
    }
  }, [conferenceContext, getChoices, conferences]);

  const onSelect = (result: string | null): void => {
    conferenceContext.select(result).then(() => {
      const next = conferences.find((c) => eventTitle(c) === result);
      setConference(next);
    });
  };
  return (
    <Nav
      activeKey={location.pathname}
      className="row subnav shadow-sm justify-content-end p-1 pr-4"
    >
      <Nav.Item>
        <Nav.Link
          eventKey="/sponsors/list"
          as={Link}
          to={`${url}/list`}
          className="text-info"
        >
          List
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          eventKey="/sponsors/slots"
          as={Link}
          to={`${url}/slots`}
          className="text-info"
        >
          Slots
        </Nav.Link>
      </Nav.Item>
      <NavDropdown
        id="nav-dropdown"
        title={eventTitle(conference)}
        className="text-info bg-black"
        onSelect={onSelect}
      >
        {conferences.map((c) => (
          <NavDropdown.Item
            key={eventTitle(c)}
            active={eventTitle(c) === eventTitle(conference)}
            className="text-info bg-black"
            eventKey={eventTitle(c)}
          >
            {eventTitle(c)}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    </Nav>
  );
};
