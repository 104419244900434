import React, { PropsWithChildren, useState } from 'react';
import { Button, Overlay, Popover } from 'react-bootstrap';
import { FaCheckCircle, FaExclamationCircle, FaTrash } from 'react-icons/fa';
import { MdMail } from 'react-icons/md';
import { Applicant, RoomType, RoomTypeName, UUID } from '../../main/types';
import { DiversityStatus, normalized } from './DiversityStatus';
import { RoomChoice } from './RoomChoice';

type RoommateProps = {
  applicant: Applicant;
  roommates: string[];
};

const Roommate = ({
  applicant,
  roommates,
}: RoommateProps): React.ReactElement => {
  const normRoommate = normalized(applicant.roommate);
  const icon =
    normRoommate !== '' &&
    (roommates.some((r) => normalized(r) === normalized(applicant.email)) ? (
      <FaCheckCircle className={'text-success'} />
    ) : (
      <FaExclamationCircle
        className={'text-danger'}
        title="E-Mails don't match up"
      />
    ));
  return (
    <>
      {applicant.roommate} {icon}
    </>
  );
};

type DeleteApplicantButtonProps = {
  applicant: Applicant;
  onDelete: (id?: UUID) => void;
};

const DeleteApplicantButton = ({
  applicant,
  onDelete,
}: PropsWithChildren<DeleteApplicantButtonProps>): React.ReactElement => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState<HTMLElement>();

  return (
    <>
      <Button
        className="p-0"
        variant="none"
        title={`Delete ${applicant.name} from the list`}
        onClick={(e) => {
          setTarget(e.currentTarget);
          setShow(!show);
        }}
      >
        <FaTrash />
      </Button>
      {target && (
        // <!--suppress RequiredAttributes -->
        <Overlay show={show} target={target} rootClose={true}>
          <Popover
            id={'delete-overlay'}
            className="popover-positioned-top"
            title="Cancel Application?"
          >
            <Popover.Title as="h3">
              Are you sure you want to remove:
            </Popover.Title>
            <Popover.Content>
              <h4>{applicant.name}</h4>
              <p>from the applicant list? This cannot be undone.</p>
              <Button
                className="mr-2"
                variant={'success'}
                onClick={() => {
                  onDelete(applicant.id);
                  setShow(false);
                }}
              >
                Yes
              </Button>
              <Button variant={'danger'} onClick={() => setShow(false)}>
                No
              </Button>
            </Popover.Content>
          </Popover>
        </Overlay>
      )}
    </>
  );
};

type EventsApplicantsListRowProps = {
  roomTypes: RoomType[];
  roommates: string[];
  applicant: Applicant;
  onDelete: (id?: UUID) => void;
  onForwardAttendee: (roomType: RoomTypeName) => void;
  onForwardSponsor: (roomType: RoomTypeName) => void;
  onForwardStaff: (roomType: RoomTypeName) => void;
  onForwardTrainer: (roomType: RoomTypeName) => void;
};

export const EventsApplicantsListRow = ({
  applicant,
  roomTypes,
  roommates,
  onDelete,
  onForwardAttendee,
  onForwardSponsor,
  onForwardStaff,
  onForwardTrainer,
}: EventsApplicantsListRowProps): React.ReactElement => {
  return (
    <tr>
      <td className="text-left align-baseline">{(applicant.index || 0) + 1}</td>
      <td className="text-left align-baseline">{applicant.name}</td>
      <td className="text-left align-baseline">{applicant.email}</td>
      <td className="text-center align-baseline">
        <DiversityStatus diversity={applicant.diversitySelected} />
      </td>
      <td className="text-center align-baseline">
        {applicant.requestedFinancialAid ? 'Yes' : ''}
      </td>
      <td className="text-left align-baseline">
        <Roommate applicant={applicant} roommates={roommates} />
      </td>
      <td className="text-center align-baseline actions-column">
        <Button
          className="p-0 mr-1"
          variant="none"
          role="link"
          href={`mailto:${applicant.email}`}
          title={`Send an email to ${applicant.name}`}
        >
          <MdMail />
        </Button>
        <DeleteApplicantButton applicant={applicant} onDelete={onDelete} />
        <RoomChoice
          applicant={applicant}
          roomTypes={roomTypes.filter(
            (r) => applicant.roomTypeSelected.indexOf(r.type) > -1,
          )}
          onForwardSponsor={onForwardSponsor}
          onForwardStaff={onForwardStaff}
          onForwardAttendee={onForwardAttendee}
          onForwardTrainer={onForwardTrainer}
        />
      </td>
    </tr>
  );
};
export const EmptyEventsApplicantsListRow = (): React.ReactElement => (
  <tr>
    <td className="text-center" colSpan={6}>
      There are currently no active applicants.
    </td>
  </tr>
);
