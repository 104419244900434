import {
  Applicant,
  Booking,
  Identifier,
  LotteryInput,
  RoomTypeName,
  UUID,
} from '../types';
import { config } from '../../config';
import axios from 'axios';
import {
  InternalErrorException,
  NetworkException,
  NotFoundException,
} from './error';

const applicantsApi: string = config.apiEndpoints.applicants;
const applicantsEndpoint: string = `${applicantsApi}/applicants`;

const attendeesApi: string = config.apiEndpoints.attendees;
const attendeesEndpoint: string = `${attendeesApi}/attendees`;

export interface ApplicantsApi {
  getApplicants: (conference: Identifier) => Promise<Applicant[]>;
  updateApplicant: (applicant: Applicant) => Promise<void>;
  deleteApplicant: (conference: Identifier, id?: UUID) => Promise<void>;
  moveToAttendees: (
    index?: number,
    reason?: string,
    roomType?: RoomTypeName,
  ) => Promise<void>;
  runLottery: (input: LotteryInput) => Promise<Booking[] | undefined>;
  executeLottery: (
    bookings: Booking[],
    conferenceId: Identifier,
  ) => Promise<void>;
  cancelLottery: () => Promise<void>;
}

const findAllApplicants = async (conference: UUID): Promise<Applicant[]> => {
  return (await axios.get(`${applicantsEndpoint}/${conference}`)).data;
};

const putApplicant = async (created: Applicant): Promise<void> => {
  await axios.put(`${applicantsEndpoint}`, created);
};

const deleteApplicantById = async (
  conference: Identifier,
  id: UUID,
): Promise<void> => {
  await axios.delete(`${applicantsEndpoint}/${conference}/${id}`);
};

const postApplicantsLottery = async (
  input: LotteryInput,
): Promise<Booking[]> => {
  return (await axios.post(`${applicantsEndpoint}/lottery`, input))?.data
    .bookings as Booking[];
};

const putApplicantsLottery = async (
  bookings: Booking[],
  conferenceId: UUID,
): Promise<void> => {
  await axios.put(`${applicantsEndpoint}/lottery`, { bookings, conferenceId });
};

const deleteApplicantsLottery = async (): Promise<void> => {};

export class Applicants implements ApplicantsApi {
  private _applicants: Applicant[] = [];

  public async deleteApplicant(
    conference: Identifier,
    id?: UUID,
  ): Promise<void> {
    if (id !== undefined) {
      await deleteApplicantById(conference, id);
    }
  }

  public async getApplicants(conference: UUID): Promise<Applicant[]> {
    this._applicants = (await findAllApplicants(conference)).map((a, i) => ({
      ...a,
      index: i,
    }));
    return this._applicants;
  }

  public async updateApplicant(applicant: Applicant): Promise<void> {
    const index: number | undefined = applicant.index;
    if (index !== undefined) {
      const toPut: Applicant = { ...applicant };
      toPut.index = undefined;
      delete toPut.index;
      await putApplicant(applicant);
    }
  }

  public async moveToAttendees(
    index?: number,
    reason?: string,
    roomType?: RoomTypeName,
  ): Promise<void> {
    if (index !== undefined) {
      const applicant = this._applicants.find(
        (a) => a.index !== undefined && a.index === index,
      );
      if (applicant) {
        const att: any = {
          conference: applicant.conferenceId,
          email: applicant.email,
          roommate: applicant.roommate,
          roomTypeSelected: roomType ?? 'single',
          reason,
        };
        await axios.put(`${attendeesEndpoint}`, att);
      }
    }
  }

  public async runLottery(input: LotteryInput): Promise<Booking[] | undefined> {
    return (await postApplicantsLottery(input)
      .then((bookings) => bookings.map((b, index) => ({ ...b, index })))
      .catch((e) => {
        console.error('Error while running lottery:', e);
        if (e.message.endsWith('404')) {
          throw new NotFoundException();
        } else if (e.message.endsWith('500')) {
          throw new InternalErrorException();
        } else if (e.message === 'Network Error') {
          throw new NetworkException();
        }
      })) as Booking[];
  }

  public async executeLottery(
    bookings: Booking[],
    conferenceId: UUID,
  ): Promise<void> {
    await putApplicantsLottery(
      bookings.map((b) => {
        const booking = { ...b };
        delete booking.index;
        return booking;
      }),
      conferenceId,
    );
  }

  public async cancelLottery(): Promise<void> {
    await deleteApplicantsLottery();
  }
}
