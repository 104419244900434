import { Button, Modal } from 'react-bootstrap';
import { InputField } from './form/InputField';
import React, { ChangeEventHandler } from 'react';
import { Conference } from './main/types';

type CreateEventModalProps = {
  create?: Conference;
  onChangeId: ChangeEventHandler<HTMLInputElement>;
  onClose: () => void;
  onSave: () => void;
};

export const CreateEventModal = (
  props: CreateEventModalProps,
): React.ReactElement => {
  return (
    <Modal show={props.create !== undefined} onHide={props.onClose}>
      <Modal.Header>Create a new event:</Modal.Header>
      <Modal.Body>
        <InputField
          className="mb-3"
          label={'Key:'}
          value={props.create?.id ?? ''}
          placeholder={'Provide an event key'}
          onChange={props.onChangeId}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onClose}>
          Cancel
        </Button>
        <Button variant="info" onClick={props.onSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
