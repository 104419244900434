import React, { ChangeEvent, useState } from 'react';
import './EventsScreen.scss';
import { Navigation } from '../navigation/Navigation';
import { EventsSubnav } from './EventsSubnav';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { EventsDetails } from './details/EventsDetails';
import { CreateEventModal } from '../CreateEventModal';
import { useConference } from '../main/ConferenceProvider';
import { Conference } from '../main/types';
import { EventsApplicantsList } from './applications/EventsApplicantsList';
import { EventsDashboard } from './dashboard/EventsDashboard';
import { EventsAttendeesList } from './attendees/EventsAttendeesList';
import { LotteryScreen } from './lottery/LotteryScreen';

export const EventsScreen = (): React.ReactElement => {
  const history = useHistory();
  const conferences = useConference();
  const [createConf, setCreateConf] = useState<Conference | undefined>(
    undefined,
  );

  const onChangeId = (event: ChangeEvent<HTMLInputElement>): void => {
    if (createConf) {
      const conf = { ...createConf, id: event.target.value };
      setCreateConf(conf);
    }
  };

  const onSave = async (): Promise<void> => {
    if (createConf) {
      await conferences.createConference(createConf);
      setCreateConf(undefined);
      history.push('/events/details');
    }
  };
  return (
    <div>
      <Navigation />
      <EventsSubnav
        onCreateEvent={(conf: Conference) => {
          setCreateConf(conf);
        }}
      />
      <Switch>
        <Route exact path="/events">
          <Redirect to="/events/dashboard" />
        </Route>
        <Route exact path="/events/lottery">
          <LotteryScreen />
        </Route>
        <Route exact path="/events/applicants">
          <EventsApplicantsList />
        </Route>
        <Route exact path="/events/attendees">
          <EventsAttendeesList />
        </Route>
        <Route exact path="/events/details">
          <EventsDetails />
        </Route>
        <Route exact path="/events/dashboard">
          <EventsDashboard />
        </Route>
      </Switch>
      <CreateEventModal
        create={createConf}
        onChangeId={onChangeId}
        onSave={onSave}
        onClose={() => {}}
      />
      <footer></footer>
    </div>
  );
};
