import { Applicant, RoomType, RoomTypeName } from '../../main/types';
import React, { useState } from 'react';
import { Dropdown, Modal, Button } from 'react-bootstrap';

import './RoomChoice.scss';

type RoomChoiceProps = {
  applicant: Applicant;
  roomTypes: RoomType[];
  onForwardAttendee: (roomType: RoomTypeName) => void;
  onForwardSponsor: (roomType: RoomTypeName) => void;
  onForwardStaff: (roomType: RoomTypeName) => void;
  onForwardTrainer: (roomType: RoomTypeName) => void;
};

export function RoomChoice({
  applicant,
  roomTypes,
  onForwardAttendee,
  onForwardStaff,
  onForwardSponsor,
  onForwardTrainer,
}: RoomChoiceProps): React.ReactElement {
  const [showModal, setShowModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState({
    eventKey: '',
    roomType: '',
    description: '',
  });

  const handleSelect = (
    eventKey: string,
    roomType: RoomTypeName,
    description: string,
  ) => {
    setSelectedAction({ eventKey, roomType, description });
    setShowModal(true);
  };

  const confirmAction = () => {
    const { eventKey, roomType } = selectedAction;
    const actionMap = {
      attendee: onForwardAttendee,
      sponsor: onForwardSponsor,
      staff: onForwardStaff,
      trainer: onForwardTrainer,
    } as any;
    actionMap[eventKey](roomType);
    setShowModal(false); // Close the modal after action
  };

  return (
    <>
      <Dropdown className="d-inline mx-2">
        <Dropdown.Toggle variant="danger" id="dropdown-room-choice">
          Assign a seat
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Header className="dropdown-header-divider">
            Attendee
          </Dropdown.Header>
          {roomTypes.map((r, index) => (
            <Dropdown.Item
              key={`attendee-${index}`}
              eventKey="attendee"
              onClick={() => handleSelect('attendee', r.type, r.description)}
              className="room-selection"
            >
              Attendee - {r.description}
            </Dropdown.Item>
          ))}
          <Dropdown.Divider />
          <Dropdown.Header className="dropdown-header-divider">
            Sponsor
          </Dropdown.Header>
          {roomTypes.map((r, index) => (
            <Dropdown.Item
              key={`sponsor-${index}`}
              eventKey="sponsor"
              onClick={() => handleSelect('sponsor', r.type, r.description)}
              className="room-selection"
            >
              Sponsor - {r.description}
            </Dropdown.Item>
          ))}
          <Dropdown.Divider />
          <Dropdown.Header className="dropdown-header-divider">
            Staff
          </Dropdown.Header>
          {roomTypes.map((r, index) => (
            <Dropdown.Item
              key={`staff-${index}`}
              eventKey="staff"
              onClick={() => handleSelect('staff', r.type, r.description)}
              className="room-selection"
            >
              Staff - {r.description}
            </Dropdown.Item>
          ))}
          <Dropdown.Divider />
          <Dropdown.Header className="dropdown-header-divider">
            Trainer
          </Dropdown.Header>
          {roomTypes.map((r, index) => (
            <Dropdown.Item
              key={`trainer-${index}`}
              eventKey="trainer"
              onClick={() => handleSelect('trainer', r.type, r.description)}
              className="room-selection"
            >
              Trainer - {r.description}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Seat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Details:</h5>
          <ul>
            <li>
              <strong>Selected Seat Type:</strong> {selectedAction.roomType}
            </li>
            <li>
              <strong>Applicant Name:</strong> {applicant.name}
            </li>
            <li>
              <strong>Room Description:</strong>
              {selectedAction.description}
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={confirmAction}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
