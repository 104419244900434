import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { InputField } from '../../form/InputField';
import { TextArea } from '../../form/TextArea';
import { Template } from '../../main/types';

type NewsletterTemplateEditModalProps = {
  edit: Template;
  onChangeTitle: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeSubject: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeHtmlText: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onChangeText: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyPress?: () => void;
  onClose: () => void;
  onSave: () => Promise<void>;
};
export const NewsletterTemplateEditModal = (
  props: NewsletterTemplateEditModalProps,
): React.ReactElement => {
  const keyPressHandler = props.onKeyPress ?? (() => {});
  return (
    <Modal show={props.edit !== null} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputField
          className="mb-3"
          label={'Title'}
          value={props.edit?.title ?? ''}
          placeholder={'Give a meaningful name'}
          onChange={props.onChangeTitle}
          onKeyPress={keyPressHandler}
        />
        <InputField
          className="mb-3"
          label={'Subject'}
          value={props.edit?.subject ?? ''}
          placeholder={'Summarize email content'}
          onChange={props.onChangeSubject}
          onKeyPress={keyPressHandler}
        />
        <TextArea
          label={'Text (HTML)'}
          value={props.edit?.htmlText ?? ''}
          placeholder={'Template body with HTML markup'}
          onChange={props.onChangeHtmlText}
        />
        <TextArea
          label={'Text'}
          value={props.edit?.text ?? ''}
          placeholder={'Template body'}
          onChange={props.onChangeText}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.onClose}>
          Close
        </Button>
        <Button variant="info" onClick={props.onSave}>
          Save changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
