import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
import { ConferencesApi } from './api/conferences';
import { Conference, ConferenceState, Identifier } from './types';

type ConferenceContext = {
  current: Conference | undefined;
  select: (item: string | null) => Promise<void>;
  choices: () => Promise<Conference[]>;
  updateConference: (conf: Conference) => Promise<void>;
  deleteConference: (conf: Conference) => Promise<void>;
  createConference: (conf: Conference) => Promise<void>;
};
export const SOCRATES_21: Identifier = 'socrates_21';
export const SOCRATES_22: Identifier = 'socrates_22';
const DEFAULT_CONFS: Conference[] = [
  {
    id: SOCRATES_21,
    title: 'SoCraTes',
    year: 2021,
    location: 'remote/online',
    state: ConferenceState.CONCLUDED,
  },
  {
    id: SOCRATES_22,
    title: 'SoCraTes',
    year: 2022,
    location: 'Hotel Park, Soltau',
    roomTypes: [
      {
        type: 'single',
        description: 'Single Room',
        pricePerNight: '65,20€',
        beds: 1,
        count: 120,
      },
      {
        type: 'junior-double',
        description: 'Junior Double Room',
        pricePerNight: '65,20€',
        beds: 1,
        count: 42,
      },
      {
        type: 'junior-double-shared',
        description: 'Bed in Junior Double Room',
        pricePerNight: '47,95€',
        beds: 2,
        count: 16,
      },
      {
        type: 'double-shared',
        description: 'Bed in Double Room',
        pricePerNight: '45,45€',
        beds: 2,
        count: 14,
      },
    ],
    flatFees: [
      { type: 'conference', description: 'Main conference', price: '157,60€' },
      { type: 'training', description: 'Training Day', price: '100,00€' },
      { type: 'workshops', description: 'Workshops Day', price: '58,30€' },
    ],
    state: ConferenceState.PREPARATION,
  },
];

const DEFAULT_CONTEXT: ConferenceContext = {
  current: DEFAULT_CONFS[1],
  choices: () => {
    console.log('not getting choices');
    return Promise.resolve([]);
  },
  select: (item: string | null) => {
    console.log('not selecting ' + item);
    return Promise.resolve();
  },
  updateConference: (conf: Conference) => {
    console.log('not updating ' + conf);
    return Promise.resolve();
  },
  deleteConference: (conf: Conference) => {
    console.log('not deleting ' + conf);
    return Promise.resolve();
  },
  createConference: (conf: Conference) => {
    console.log('not creating ' + conf);
    return Promise.resolve();
  },
};

const conferenceContext = createContext(DEFAULT_CONTEXT);
export const useConference = (): ConferenceContext =>
  useContext(conferenceContext);

type ProvideConferencesProps = {
  conferencesApi: ConferencesApi;
};

export const ConferenceProvider = ({
  conferencesApi,
  children,
}: PropsWithChildren<ProvideConferencesProps>): React.ReactElement => {
  const [current, setCurrent] = useState<Conference | undefined>();

  const select = async (item: string | null): Promise<void> => {
    if (item !== null) {
      const pTitle = item.substr(0, item.lastIndexOf(' '));
      const pYear = parseInt(item.substr(item.lastIndexOf(' ') + 1));
      const confs = await conferencesApi.getConferences();
      const found: Conference | undefined = confs.find(
        (c) => c.title === pTitle && c.year === pYear,
      );
      if (found !== undefined) {
        setCurrent(found);
      }
    }
  };

  const context = {
    current,
    choices: conferencesApi.getConferences,
    createConference: conferencesApi.createConference,
    updateConference: conferencesApi.updateConference,
    deleteConference: conferencesApi.deleteConference,
    select,
  };

  return (
    <conferenceContext.Provider value={context}>
      {children}
    </conferenceContext.Provider>
  );
};
