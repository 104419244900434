import React from 'react';
import './SponsorsScreen.scss';
import { Navigation } from '../navigation/Navigation';
import { SponsorsSubnav } from './SponsorsSubnav';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SponsorsList } from './list/SponsorsList';
import { SponsorSlotsList } from './slots/SponsorSlotsList';

export const SponsorsScreen = (): React.ReactElement => (
  <div>
    <Navigation />
    <SponsorsSubnav />
    <Switch>
      <Route exact path="/sponsors">
        <Redirect to="/sponsors/list" />
      </Route>
      <Route path="/sponsors/list">
        <SponsorsList />
      </Route>
      <Route path="/sponsors/slots">
        <SponsorSlotsList />
      </Route>
    </Switch>
    <footer></footer>
  </div>
);
