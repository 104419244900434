import { Button, Modal, Form } from 'react-bootstrap';
import {
  Attendee,
  Day,
  Package,
  RoomOccupancy,
  RoomTypeName,
} from '../../main/types';
import React, { useState } from 'react';
import { AttendeeUpdate } from '../../main/api/attendees';

type EventsAttendeeEditModalProps = {
  attendee: Attendee | null;
  onAttendeeDetailsChange: (email: string, attendee: AttendeeUpdate) => void;
  onBookingChange: (
    email: string,
    packages: Package[],
    room: Partial<RoomOccupancy>,
  ) => void;
  onClose: () => void;
};

export const EventsAttendeeEditModal = ({
  attendee,
  onAttendeeDetailsChange,
  onBookingChange,
  onClose,
}: EventsAttendeeEditModalProps): React.ReactElement => {
  const [reason, setReason] = useState(attendee?.reason);

  const [room, setRoom] = useState(attendee?.booking.room);
  const [packages, setPackages] = useState(attendee?.booking.packages);

  const changeReason = (reason: string): void => {
    switch (reason) {
      case 'trainer':
      case 'sponsor':
      case 'staff':
        setReason(reason);
        break;
      default:
        // Backend expects undefined for attendee
        setReason(undefined);
    }
  };

  const saveAttendeeDetails = async (): Promise<void> => {
    if (attendee) {
      onAttendeeDetailsChange(attendee.email, { reason });
    }
  };

  const addOrRemovePackage = (selectedPackage: Package): void => {
    if (packages) {
      const updatedPackages = [...packages];
      const index: number = updatedPackages.indexOf(selectedPackage);
      if (index > -1) {
        updatedPackages.splice(index, 1);
      } else {
        updatedPackages.push(selectedPackage);
      }
      setPackages(updatedPackages);
    }
  };

  const addOrRemoveDay = (day: Day): void => {
    if (room) {
      const daysSelected = [...room.daysSelected];
      const index: number = daysSelected.indexOf(day);
      if (index > -1) {
        daysSelected.splice(index, 1);
      } else {
        daysSelected.push(day);
      }
      const week = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
      daysSelected.sort((a, b) => {
        return week.indexOf(a) - week.indexOf(b);
      });
      setRoom({ ...room, daysSelected });
    }
  };

  const saveBooking = async (): Promise<void> => {
    if (attendee && room && packages) {
      onBookingChange(attendee.email, packages, {
        roomType: room.roomType,
        roommate: room.roommate,
        family: room.family,
        daysSelected: room.daysSelected,
      });
    }
  };

  return (
    <Modal show={attendee !== null} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Attendee</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {reason && (
          <Form>
            <h3>Reason</h3>
            <Form.Group controlId="formReason">
              <Form.Control
                as="select"
                value={reason}
                onChange={(e) => changeReason(e.target.value)}
              >
                {['attendee', 'staff', 'sponsor', 'trainer'].map((reason) => (
                  <option key={reason} value={reason}>
                    {reason ?? 'attendee'}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Button type="button" onClick={saveAttendeeDetails}>
              Save Attendee
            </Button>
          </Form>
        )}
        <hr />

        {room && packages && (
          <Form>
            <h3>Change Booking</h3>
            <h4>Packages</h4>

            <Form.Group controlId="formPackagesSelected">
              <div>
                {(['training', 'conference', 'workshops'] as Package[]).map(
                  (availablePackage) => (
                    <Form.Check
                      key={availablePackage}
                      id={`checkbox-packages-${availablePackage}`}
                      type="checkbox"
                      label={availablePackage}
                      checked={packages.includes(availablePackage)}
                      onChange={() => addOrRemovePackage(availablePackage)}
                    />
                  ),
                )}
              </div>
            </Form.Group>

            <h4>Room</h4>
            <Form.Group controlId="formRoomType">
              <Form.Label column={true}>Room Type</Form.Label>
              <Form.Control
                as="select"
                value={room.roomType}
                onChange={(e) =>
                  setRoom({ ...room, roomType: e.target.value as RoomTypeName })
                }
              >
                {[
                  'single',
                  'double-shared',
                  'junior-double',
                  'junior-double-shared',
                ].map((roomType) => (
                  <option key={roomType} value={roomType}>
                    {roomType}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formRoommateEmail">
              <Form.Label column={true}>Roommate Email</Form.Label>
              <Form.Control
                type="email"
                value={room.roommate}
                onChange={(e) =>
                  setRoom({
                    ...room,
                    roommate:
                      e.target.value.trim().length === 0
                        ? undefined
                        : e.target.value.trim(),
                  })
                }
              />
            </Form.Group>

            <Form.Group controlId="formFamily">
              <Form.Check
                type="checkbox"
                label="Family"
                checked={room.family}
                onChange={(e) => setRoom({ ...room, family: e.target.checked })}
              />
            </Form.Group>

            <Form.Group controlId="formDaysSelected">
              <Form.Label column={true}>Days</Form.Label>
              <div>
                {['Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => (
                  <Form.Check
                    key={day}
                    id={`checkbox-days-${day}`}
                    type="checkbox"
                    label={day}
                    checked={room.daysSelected.includes(day as Day)}
                    onChange={() => addOrRemoveDay(day as Day)}
                  />
                ))}
              </div>
            </Form.Group>
            <Button type="button" onClick={saveBooking}>
              Save Booking Change
            </Button>
          </Form>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
