import { Attendee, Identifier, Package, RoomOccupancy, UUID } from '../types';
import { config } from '../../config';
import axios, { AxiosResponse } from 'axios';

const attendeesApi: string = config.apiEndpoints.attendees;
const attendeesEndpoint: string = `${attendeesApi}/attendees`;

const sortByNames: (a1: Attendee, a2: Attendee) => number = (a1, a2) =>
  `${a1.personalAddress?.lastname},${a1.personalAddress?.firstname},${a1.nickname}`.localeCompare(
    `${a2.personalAddress?.lastname},${a2.personalAddress?.firstname},${a2.nickname}`,
  );

export interface AttendeeUpdate {
  reason?: string;
}

export interface AttendeesApi {
  getAttendees: (conference: Identifier) => Promise<Attendee[]>;
  updateAttendee: (attendee: Attendee) => Promise<void>;
  deleteAttendee: (id?: UUID) => Promise<void>;
  resetAccount: (conference: Identifier, email?: string) => Promise<void>;
  resendConfirmation: (conference: Identifier, email?: string) => Promise<void>;
  changeAttendeeDetails: (
    conference: Identifier,
    email: string,
    update: AttendeeUpdate,
  ) => Promise<void>;
  changeBooking: (
    conference: Identifier,
    email: string,
    update: {
      packages?: Package[];
      room?: Partial<RoomOccupancy>;
    },
  ) => Promise<void>;
  lockProfiles: (conferenceId: UUID, attendees: Attendee[]) => Promise<void>;
  downloadBadgesXML: (conference: Identifier) => Promise<void>;
  downloadCsv: (conference: Identifier) => Promise<void>;
  downloadStripe: (conference: Identifier) => Promise<void>;
}

const findAllAttendees = async (
  conference: Identifier,
): Promise<Attendee[]> => {
  return (await axios.get(`${attendeesEndpoint}/${conference}`)).data;
};

const putAttendee = async (created: Attendee): Promise<void> => {
  await axios.put(`${attendeesEndpoint}`, created);
};

const putLockAttendees = async (
  conferenceId: UUID,
  attendees: Attendee[],
): Promise<void> => {
  await axios.put(
    `${attendeesEndpoint}/lock/${conferenceId}`,
    attendees.map((a) => a.id),
  );
};

const deleteAttendeeById = async (id: UUID): Promise<void> => {
  await axios.delete(`${attendeesEndpoint}/${id}`);
};

const putAttendeeResetByEmail = async (
  conference: Identifier,
  email: string,
): Promise<void> => {
  await axios.put(`${attendeesEndpoint}/reset/${conference}/${email}`);
};

const putAttendeeResendByEmail = async (
  conference: Identifier,
  email: string,
): Promise<void> => {
  await axios.put(`${attendeesEndpoint}/resend/${conference}/${email}`);
};

const patchAttendeeChangeBooking = async (
  conference: Identifier,
  email: string,
  update: { packages?: Package[]; room?: Partial<RoomOccupancy> },
): Promise<void> => {
  await axios.patch(
    `${attendeesEndpoint}/booking/${conference}/${email}`,
    update,
  );
};

const patchAttendeeDetails = async (
  conference: Identifier,
  email: string,
  update: AttendeeUpdate,
): Promise<void> => {
  await axios.patch(
    `${attendeesEndpoint}/details/${conference}/${email}`,
    update,
  );
};

const download = (response: AxiosResponse<any>): void => {
  const fileName =
    response.headers['content-disposition'].split('filename=')[1];
  const contentType = response.headers['content-type'];
  // @ts-ignore
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // IE variant
    // @ts-ignore
    window.navigator.msSaveOrOpenBlob(
      new Blob([response.data], { type: contentType }),
      fileName,
    );
  } else {
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: contentType }),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }
};

export class Attendees implements AttendeesApi {
  private _attendees: Attendee[] = [];

  public async deleteAttendee(id?: UUID): Promise<void> {
    if (id !== undefined) {
      console.log('delete', id);
      await deleteAttendeeById(id);
    }
  }

  public async resetAccount(
    conference: Identifier,
    email?: string,
  ): Promise<void> {
    if (email !== undefined && conference !== undefined) {
      await putAttendeeResetByEmail(conference, email);
    }
  }

  public async resendConfirmation(
    conference: Identifier,
    email?: string,
  ): Promise<void> {
    if (email !== undefined && conference !== undefined) {
      await putAttendeeResendByEmail(conference, email);
    }
  }

  public async changeBooking(
    conference: Identifier,
    email: string,
    update: { packages?: Package[]; room?: Partial<RoomOccupancy> },
  ): Promise<void> {
    await patchAttendeeChangeBooking(conference, email, update);
  }

  public async changeAttendeeDetails(
    conference: string,
    email: string,
    update: AttendeeUpdate,
  ): Promise<void> {
    await patchAttendeeDetails(conference, email, update);
  }

  public async getAttendees(conference: Identifier): Promise<Attendee[]> {
    this._attendees = (await findAllAttendees(conference)).map((a, i) => ({
      ...a,
      index: i,
    }));
    return this._attendees.sort(sortByNames);
  }

  public async updateAttendee(attendee: Attendee): Promise<void> {
    const index: number | undefined = attendee.index;
    if (index !== undefined) {
      const toPut: Attendee = { ...attendee };
      toPut.index = undefined;
      delete toPut.index;
      await putAttendee(attendee);
    }
  }

  public async lockProfiles(
    conferenceId: UUID,
    attendees: Attendee[],
  ): Promise<void> {
    await putLockAttendees(conferenceId, attendees);
  }

  public async downloadBadgesXML(conference: Identifier): Promise<void> {
    const response = await axios.get(
      `${attendeesEndpoint}/${conference}/export`,
      { responseType: 'blob', params: { format: 'xml' } },
    );
    console.log(response);
    download(response);
  }

  public async downloadCsv(conference: Identifier): Promise<void> {
    const response = await axios.get(
      `${attendeesEndpoint}/${conference}/export`,
      { responseType: 'blob' },
    );
    console.log(response);
    download(response);
  }

  public async downloadStripe(conference: Identifier): Promise<void> {
    const response = await axios.get(
      `${attendeesEndpoint}/${conference}/export`,
      { responseType: 'blob', params: { format: 'stripe' } },
    );
    console.log(response);
    download(response);
  }
}
