import React from 'react';
import { Button } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import { MdMail } from 'react-icons/md';
import { Booking } from '../../main/types';

type LotteryBookingsListRowProps = {
  index: number;
  booking: Booking;
};
export const LotteryBookingsListRow = ({
  index,
  booking,
}: LotteryBookingsListRowProps): React.ReactElement => {
  return (
    <tr>
      <td className="text-left align-baseline">{(index || 0) + 1}</td>
      <td className="text-left align-baseline">{booking.email}</td>
      <td className="text-left align-baseline">
        {booking.room.roomType ?? ''}
      </td>
      <td className="text-center align-baseline">
        {booking.room.diversity ? <FaCheckCircle /> : <>&nbsp;</>}
      </td>
      <td className="text-center align-baseline actions-column">
        <Button
          className="p-0 mr-1"
          variant="none"
          role="link"
          href={`mailto:${booking.email}`}
          title={`Send an email to ${booking.email}`}
        >
          <MdMail />
        </Button>
      </td>
    </tr>
  );
};
export const EmptyLotteryBookingsListRow = (): React.ReactElement => (
  <tr>
    <td className="text-center" colSpan={5}>
      There are currently no active lottery runs.
    </td>
  </tr>
);
