import { Button, ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import React from 'react';
import { SelectCallback, TableRow } from '../main/types';

type PagingRowProps = {
  page: number;
  pageSize: number;
  rows: TableRow[];
  onPrevPage: () => void;
  minItem: number;
  maxItem: number;
  maxPage: number;
  onNextPage: () => void;
  onSelect: SelectCallback;
};
export const PagingRow = (props: PagingRowProps): React.ReactElement => (
  <div className="row">
    <div className="col text-sm-left">
      <span className="text-sub">
        {props.page > 0 && (
          <Button
            variant="none"
            className="p-0"
            onClick={props.onPrevPage}
            title="Previous page"
          >
            <FaArrowLeft />
          </Button>
        )}
        {props.page < props.maxPage && (
          <Button
            variant="none"
            className="p-0"
            title="Next page"
            onClick={props.onNextPage}
          >
            <FaArrowRight />
          </Button>
        )}
      </span>
    </div>
    <div className="col text-sm-right">
      <DropdownButton
        as={ButtonGroup}
        variant="info"
        onSelect={props.onSelect}
        title="Items per page"
      >
        {[10, 25, 50, 100, 500].map((size) => (
          <Dropdown.Item
            key={size}
            eventKey={'' + size}
            active={props.pageSize === size}
          >
            <Dropdown.ItemText className="text-sub">{size}</Dropdown.ItemText>
          </Dropdown.Item>
        ))}
      </DropdownButton>
      <span className="text-sub ml-2">
        Showing {props.minItem} &gt; {props.maxItem} / {props.rows.length} total
      </span>
    </div>
  </div>
);
